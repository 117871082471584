import { createSelector } from "reselect";

const Application = (state) => state.application;

export const showDialogSelector = createSelector(
    Application,
    (Application) => Application.showDialog
)

export const dialogContentSelector = createSelector(
    Application,
    (Application) => Application.dialogContent
)
