import { createSelector } from "reselect";

const Stores = (state) => state.stores;

export const selectedStoreSelector = createSelector(
  Stores,
  (Stores) => Stores.selectedStore
);

export const itemSelector = createSelector(Stores, (Stores) => Stores.items);

export const logItemsSelector = createSelector(
  Stores,
  (Stores) => Stores.logItems
);

export const paginationSelector = createSelector(
  Stores,
  (Stores) => Stores.pagination
);

export const searchSelector = createSelector(Stores, (Stores) => Stores.search);
