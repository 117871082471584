import { Role } from "./Role";
export class User {
  public id: number = 0;
  public firstName: string = "";
  public lastName: string = "";
  public username: string = "";
  public roleId: number = 0;
  public passwordHash: string = "";
  public passwordSalt: string = "";
  // public contactPhone: string = '';
  //public name: string = '';
  public role: Role = new Role();
}
