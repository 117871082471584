import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAppSettings } from "../services/AppSettings/appSettingsService";

export const getAppSettingsAsync = createAsyncThunk(
  "product/product-stock",
  async (val, { rejectWithValue }) => {
    return getAppSettings()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue(error);
      });
  }
);

const applicationSettingsSlice = createSlice({
  name: "globalSearch",
  initialState: {
    applicationSettings: {},
  },
  reducers: {
    setApplicationSettings: (state, action) => {
      state.applicationSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppSettingsAsync.fulfilled, (state, actions) => {
      state.applicationSettings = actions.payload
        ? Object.fromEntries(
            actions.payload.map(({ name, value }) => {
              return [name, value];
            })
          )
        : {};
    });
  },
});

export const { setApplicationSettings } = applicationSettingsSlice.actions;

export const applicationSettings = applicationSettingsSlice.reducer;
