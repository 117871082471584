import React, { useEffect } from "react";
import {
  searchRoles,
  removeRole,
} from "../../services/Roles/roleService";
import { CustomTable } from "../Common/CustomTable";
import { useAppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import RolesMobile from "../../components/Role/RolesMobile";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  itemSelector,
  paginationSelector,
} from "../Role/store/rolesSelector";
import { setPageSize, setPageNumber, getItemsAsync } from "./store/rolesSlice";


const menuItemColumns = [
  { id: "roleName", numeric: false, disablePadding: true, label: "Role Name" },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
];

const RolesLog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, roleHelper } = useAppContext();
  const paginationState = useSelector(paginationSelector, shallowEqual);
  const roles = useSelector(itemSelector, shallowEqual);
  const pagination = {
    paginationState,
    setPageSize,
    setPageNumber,
  };

  const handleAdd = () => {
    history.push("/roles/add/0");
  };

  const handeEdit = (id: number) => {
    history.push(`/roles/edit/${id}`);
  };

  useEffect(() => {
    dispatch(getItemsAsync({searchText: "" }));
  }, []);

  return (
    <>
      {isMobile ? (
        <RolesMobile rows={roles}/>
      ) : (
        <CustomTable
          tableTitle="roles"
          rows={roles}
          cellHeads={menuItemColumns}
          handleAdd={handleAdd}
          handeEdit={handeEdit}
          deleteData={removeRole}
          isSuperAdmin={roleHelper.isSuperAdmin()}
          pagination={pagination}
        />
      )}
    </>
  );
};

export default RolesLog;
