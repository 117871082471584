import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from '@material-ui/core';
import { BackButton } from '../Common/backButton';
import React, { useState, useEffect } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SaveIcon from '@material-ui/icons/Save'
import { useParams } from 'react-router';
import { Contact } from '../../models/Contact';
import { saveContact, getContact } from '../../services/Contacts/contactService';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Title } from '../../helpers/stringExtensions'


export const AddContact = () => {
    const [contact, setContact] = useState(new Contact());
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { mode, contactId } = useParams();
    const [actions, setActions] = useState([
        { icon: <PersonAddIcon />, name: "Add Groups", sourceType: "addGroup" },
        { icon: <SaveIcon />, name: "Send Distributions", sourceType: "group" },
    ]);
    let history = useHistory();

    useEffect(() => {

        if (mode === "edit" && contactId > 0) {
            loadContact();
        }
    },[])

    const loadContact = async () => {
        setLoading(true);
        try {
            const { data: loadedContact } = await getContact(contactId);
            if (loadedContact) {
                setContact(loadedContact);
            }
            else {
                enqueueSnackbar('failed to add contact.', { variant: "error" });
            }
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('failed to add contact.', { variant: "error" });
        }
        finally {
            setLoading(false);
        }
    }

    const handleChange = (event) => {
        console.log(event);
        let value = event.currentTarget.value;
        let key = event.currentTarget.id;
        contact[key] = value;
        setContact({ ...contact });
    }

    const handleSaveOnClick = async () => {
        setLoading(true);
        try {
            contact.clientId = 1;
            const { data: success } = await saveContact(contact.id, contact);

            if (success) {
                enqueueSnackbar('successfully saved.', { variant: "success" });
                history.goBack();
            }
            else {
                enqueueSnackbar('failed to save contact.', { variant: "error" });
            }
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('failed to add contact.', { variant: "error" });
        }
        finally {
            setLoading(false);
        }


    }
    return (
        <>
            <Card>
                <CardHeader
                    title={Title(`${mode} Contact`)}
                    action={
                        <>
                            <BackButton />
                        </>
                    }
                />
                <CardContent>
                    <Grid container direction="row" spacing={2} md={6}>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id='firstName'
                                label='First name:'
                                value={contact.firstName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id='lastName'
                                label='Last name:'
                                value={contact.lastName}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id='phoneNumber'
                                label='Phone Number:'
                                value={contact.phoneNumber}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id='email'
                                label='Email:'
                                value={contact.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveOnClick}
                            >
                                {loading && <CircularProgress color="secondary" />}
                                {!loading && "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default AddContact;