import React from "react";
import "./styles.css";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconButton, makeStyles, Typography } from "@material-ui/core";
import clsx from 'clsx'

const useStyles = makeStyles({
    dpad:{
        height: "150px",
        width: "250px"
    },
    upArrow:{
        position: "relative",
        top: -40
    }
})

const DirectionalPad = ({handleLeftClick, handleRightClick, handleUpClick, handleDownClick}) => {
    const classes = useStyles();

  return (
    <>
        <Typography>Live Positional Update</Typography>
      <div className={clsx(classes.dpad,"set")}>
        <IconButton onClick={handleLeftClick}>
          <ChevronLeftIcon />
        </IconButton>
        <IconButton className={classes.upArrow} onClick={handleUpClick}>
          <ExpandLessIcon />
        </IconButton>
        <IconButton onClick={handleRightClick}>
          <ChevronRightIcon />
        </IconButton>
        <IconButton onClick={handleDownClick}> 
          <ExpandMoreIcon />
        </IconButton>
        
      </div>
    </>
  );
};

export default DirectionalPad;
