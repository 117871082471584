import { Client } from './Client';
import { GroupContact } from './GroupContact';

export class Group {
    public id: number = 0;
    public clientId: number = 0;
    public name: string = '';
    public description: string = '';
    public createdby: string = "";
    public createdate: string = new Date().toISOString();
    //relationships
    //public client: Client = new Client();
    public groupContacts: GroupContact[] = [];
}