import axios from 'axios';
import { apiUrl } from '../../config/appConfig';
import { Group } from '../../models/Group';

const endpoint = `${apiUrl}/api/Groups`

export const getGroups = async () => {
    return await axios.get(`${endpoint}`);
}

export const getGroup = (groupId: number) => {
    return axios.get(`${endpoint}/${groupId}`);
}

export const addGroup = async (newGroup: Group) => {
    return await axios.post(`${endpoint}`, newGroup);
}

export const updateGroup = (groupId: number, updatedGroup: Group) => {
    return axios.put(`${endpoint}/${groupId}`, updatedGroup);
}

export const saveGroup = (groupId: number, updatedGroup: Group) => {
    return axios.post(`${endpoint}/save/${groupId}`, updatedGroup);
}

export const deleteGroup = (groupId: number) => {
    return axios.delete(`${endpoint}/${groupId}`);
}