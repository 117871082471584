import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { getToken } from "../../services/Login/logService";
import { LoginRequest } from "../../models/LoginRequest";
import { useHistory } from "react-router";
import { useAppContext } from "../../context/AppContext";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import * as Cookies from "js-cookie";
import geiImg from "../../images/logos/gei_logo_2.png";
import { useParams } from "react-router";
import {
  resetPassword,
  emailPaswordReset,
} from "../../services/Users/userService";
import { PasswordReset } from "../../models/PasswordReset";
import { EmailPasswordReset } from "../../models/EmailPasswordReset";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    maxWidth: "500px",
    maxHeight: "750px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  input: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "16px",
  },
  inputExtras: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
  },
}));

interface IPasswordReset {
  userId: string;
  token: string;
}

const ResetPasswordRequest = () => {
  const history = useHistory();
  const classes = useStyles();

  const { setAuthorized, setToken, setGlobalUser, setAppRoles } =
    useAppContext();
  const [showPassword, setShowPassword] = useState(false);
  const [emailPasswordResetObj, setEmailPasswordResetObj] = useState<EmailPasswordReset>(new EmailPasswordReset());
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    resetUserPassword();
  };

  const resetUserPassword = async () => {
    try {
      var result = await emailPaswordReset(emailPasswordResetObj);
    } catch (error) {}

    if (result) {
        enqueueSnackbar("password reset email sent successfully", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("failed to send password reset email", { variant: "error" });
      }
  };

  const onChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let updatedEmailPasswordResetObj = { ...emailPasswordResetObj };
    updatedEmailPasswordResetObj[name] = value;
    setEmailPasswordResetObj(updatedEmailPasswordResetObj);
  };

  return (
    <div className={clsx(classes.root)}>
      <div>
        <Card>
          <CardContent className={classes.inputExtras}>
            <img
              src={geiImg}
              alt="gei_logo"
              width="409px"
              height="265px"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
            <Typography variant="h6" className={classes.inputExtras}>
              Reset your Password
            </Typography>
            <Typography variant="subtitle1" className={classes.inputExtras}>
              Enter the email address associated with your account to
              get instructions to reset your password.
            </Typography>
            <br/><br/>
            <form onSubmit={handleSubmit}>
              <TextField
                name="email"
                className={classes.input}
                label="Email"
                autoFocus
                type="text"
                //error={!!errors.email}
                //helperText={errors?.email?.message}
                variant="outlined"
                disabled={loading}
                required
                fullWidth
                defaultValue={emailPasswordResetObj.email}
                value={emailPasswordResetObj.email}
                onChange={onChange}
              />
              <div className={classes.inputExtras}>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="RESET"
                  //disabled={_.isEmpty(dirtyFields) || !isValid}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Reset Password"}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
