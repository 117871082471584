let url;

const hostname = window && window.location && window.location.hostname;

if (hostname === "localhost") {
  url = "http://localhost:52818";
} else if (hostname === "dandev-dev-001-webui.azurewebsites.net") {
  url = "https://dandev-dev-001-web.azurewebsites.net"
} else {
  url = "https://mydmbapi.geidigital.com";
}
url = "https://mydmbapi.geidigital.com";
export const apiUrl = url;
