import React from "react";
import { Paper, InputBase } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const CommonSearch = ({search}) => {

    const handleSearch = ({ target: { value } }) => {
        if (search) {
            search.handleSearch(value);
        }
    };

    return (
     <Paper
        className={"flex items-center w-full px-8 rounded-8"}
        elevation={1}
         >
        <SearchIcon />
        <InputBase
            placeholder="Search…"
            className="flex flex-1 mx-8"
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearch}
        />
    </Paper>
    )
}

export default CommonSearch;