import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { BackButton } from "../Common/backButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface ISettings {
  darkMode: boolean;
}

const Settings = () => {
  const [settings, setSettings] = useState<ISettings>({
    darkMode: false,
  });
  return (
    <>
      <Card>
        <CardHeader title={"Settings"} action={<BackButton />} />
        <CardContent>
          <Typography variant="h6">User Settings</Typography>
          <Grid container direction="row" xs={12} spacing={10}>
            <Typography variant="subtitle1">Under Construction</Typography>
          </Grid>
          <Typography variant="h6">Client Settings</Typography>
          <Grid container direction="row" xs={12} spacing={10}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Under Construction</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default Settings;
