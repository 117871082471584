import {
    Paper,
    Grid,
    TextField,
    Card,
    CardContent,
    CardHeader,
    Button,
    Divider,
    InputAdornment,
  } from "@material-ui/core";
  import React, { useEffect, useState } from "react";
  import { BackButton } from "../../components/Common/backButton";
  import MenuItemTypeSelector from "../../components/MenuItemType/MenuItemTypeSelector";
  import { useParams } from "react-router";
  import {
    saveRole,
    createRole,
    getRoleById,
  } from "../../services/Roles/roleService";
  import { Role } from "../../models/Role";
  import { useSnackbar } from "notistack";
  import { useValidator } from "../../hooks/validatorHook";
  import { roleValidator } from "../../validations/roleValidator";
  import { formatter } from "../../helpers/formatter";
  import {useAppContext} from "../../context/AppContext";
  interface IRoleParams {
    mode: string;
    roleId: string;
  }
  const RolesEdit = () => {
    const [role, setRole] = useState<Role>(new Role());
    const { mode, roleId } = useParams<IRoleParams>();
    const { enqueueSnackbar } = useSnackbar();
    const {roleHelper} = useAppContext();
  
    const { validator, setShouldShowErrors } = useValidator(
        roleValidator,
      role
    );
  
    const isSuperAdmin = roleHelper.isSuperAdmin();

    useEffect(() => {
      if (mode == "edit") {
        loadRole();
      }
      return () => {};
    }, []);
  
    const loadRole = async () => {
      try {
        const { data: menuItemResult } = await getRoleById(roleId);
        if (menuItemResult) {
            setRole({...menuItemResult});
        }
      } catch (error) {
        enqueueSnackbar("failed to load menu items group.", { variant: "error" });
        enqueueSnackbar(error, { variant: "error" });
      }
    };
    const validateData = () => {
      const isValid = false;
      if (validator) {
        const _isValid = validator.isValid();
        setShouldShowErrors(!_isValid);
        return _isValid;
      }
      return isValid;
    };
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;
      setRole({ ...role, [name]: value });
    };
  
    const onSave = async () => {
      console.log("Save");
      console.log(role);
      if (!validateData()) return;
      let success = false;
      try {
        if (Number(roleId) > 0) {
          const { data } = await saveRole(role.id, role);
          success = data;
        } else {
          const { data } = await createRole(role);
          success = data;
        }
        if (success) {
          enqueueSnackbar("successfully saved role", { variant: "success" });
        } else {
          enqueueSnackbar("failed to save role", { variant: "error" });
        }
      } catch (error) {
        enqueueSnackbar("failed to save role.", { variant: "error" });
      }
    };
  
    return (
      <Card>
        <CardHeader
          title={`${mode === "edit" ? "Edit" : "Add"} User Roles`}
          action={
            <>
              <BackButton />
            </>
          }
        />
        <CardContent>
          <Divider />
          <br />
          <Grid container direction="column" spacing={4} md={6}>
            {/* <Paper  style={{width: "100%%"}} elevation={3}> */}
            <Grid item xs={12} md={6}>
              <TextField
                name="roleName"
                label="Role Name"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                defaultValue={role.roleName}
                value={role.roleName}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["roleName"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["roleName"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                name="description"
                label="Description"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                multiline
                value={role.description}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["description"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["description"]
                    : null
                }
              />
            </Grid>
            {/* </Paper> */}
          </Grid>
          <br />
          <Grid item xs={12} md={6} lg={6}>
            <Button variant="contained" color="primary" onClick={onSave} disabled={!isSuperAdmin}>
              Save
            </Button>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  
  export default RolesEdit;
  