import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles, fade } from "@material-ui/core/styles";
import { Card, CardHeader } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useHistory } from "react-router";
import LinearProgress from "@material-ui/core/LinearProgress";
import displayTables from "../Tables/tables";
import { useParams } from "react-router-dom";
import { CardContent } from "@material-ui/core";
import { BackButton } from "../Common/backButton";
import { Title } from "../../helpers/stringExtensions";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTheme } from "@material-ui/core";
import { useButtonStyles } from "../../styles/buttonStyle";
import _ from "lodash";
import { useAppContext } from "../../context/AppContext";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isSuperAdmin,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ position: "sticky", zIndex: "99", top: "0" }}>
      <TableRow style={{ position: "sticky", zIndex: "99" }}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          key={"action"}
          align={true ? "right" : "left"}
          padding={false ? "none" : "default"}
          sortDirection={false ? order : false}
        >
          <TableSortLabel
            active={false}
            direction={false ? order : "asc"}
            onClick={createSortHandler("action")}
          >
            {"Action"}
            {false ? (
              <span className={classes.visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.func.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, tableTitle, onAddClick, isSuperAdmin, noAdd } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {tableTitle}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <>
          {!noAdd && (
            <Tooltip title="Add Item">
              <IconButton
                aria-label="add iten"
                disabled={!isSuperAdmin}
                onClick={onAddClick}
              >
                <Fab
                  aria-label="Add"
                  size="small"
                  className={classes.fab}
                  color="primary"
                >
                  <AddIcon />
                </Fab>
              </IconButton>
            </Tooltip>
          )}
          {/* <Tooltip title="Filter List">
            <IconButton aria-label="filter list" onClick={() => {}}>
              <FilterListIcon />
            </IconButton>
          </Tooltip> */}
        </>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  tableTitle: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  container: {
    maxHeight: 600,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    height: "32px",
    width: "100%",
    padding: "9px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0, 3),
      width: "auto",
    },
  },
  searchIcon: {
    marginRight: "8px",
    margineLeft: "8px",
    pointerEvents: "none",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const BaseTable = () => {
  const { tableObject } = useParams();
  let table = displayTables.find((t) => t.tableName === tableObject);
  if (table) {
    return (
      <CustomTable
        tableTitle={tableObject}
        getTableData={table.getTableData}
        cellHeads={table.headCells}
        deleteData={table.deleteData}
      />
    );
  } else {
    return <p>Test</p>;
  }
};

export const CustomTable = (props) => {
  const {
    tableTitle,
    getTableData,
    searchTableData,
    cellHeads,
    deleteData,
    handleAdd,
    handleEdit,
    isSuperAdmin,
    noAdd,
    noDelete,
    contextPage,
    setContextPage,
    contextRowPerPage,
    setContextRowPerPage,
    rows,
    search,
  } = props;
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);
  const { paginationState, setPageSize, setPageNumber } = props.pagination;
  const [customTableTitle, setCustomTableTitle] = useState(props.tableTitle);
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(contextPage ? contextPage : 0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    contextRowPerPage ? contextRowPerPage : 5
  );
  const [openAddCompanyUser, setOpenAddCompanyUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actions, setActions] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const buttonStyles = useButtonStyles(useTheme());
  const { searchText } = useAppContext();
  const dispatch = useDispatch();
  const updateActions = () => {
    setActions([
      {
        icon: <PersonAddIcon />,
        name: `Add ${tableTitle}`,
        sourceType: tableTitle,
      },
    ]);
  };

  const history = useHistory();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleEditClick = (event, id) => {
    if (handleEdit) {
      handleEdit();
    } else {
      history.push(`/${tableTitle}/edit/${id}`);
    }
  };

  const handleAddClick = (action) => {
    console.log(action);
    switch (action.sourceType) {
      case tableTitle:
        if (handleAdd) {
          handleAdd();
        } else {
          history.push(`/${tableTitle}/add/0`);
        }
        break;
      default:
        history.push(`/distribution`);
        break;
    }
  };

  const onAddClick = () => {
    if (handleAdd) {
      handleAdd();
    } else {
      history.push(`/${tableTitle}/add/0`);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setPageSize(parseInt(event.target.value, 10)));
    dispatch(setPageNumber(0));
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleActionClick = (action) => {
    history.push(`/table/${customTableTitle}/add/0`);
  };

  const handleSearch = ({ target: { value } }) => {
    if (search) {
      search.handleSearch(value);
    }
  };

  const handleDelete = async () => {
    await deleteData(rowToDelete.id);
    setSelected([]);
    //loadData();
    setRowToDelete(null);
  };

  const handleDeleteClick = async (event, row) => {
    setRowToDelete(row);
    setShowConfirmDelete(true);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    paginationState.pageSize -
    Math.min(
      paginationState.pageSize,
      rows.length - paginationState.pageNumber * paginationState.pageSize
    );

  return (
    <>
      <Card>
        <CardHeader title={Title(tableTitle)} action={<BackButton />} />
        <CardContent>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              {isLoading && <LinearProgress />}
              <Paper
                className={"flex items-center w-full px-8 rounded-8"}
                elevation={1}
              >
                <SearchIcon />
                <InputBase
                  placeholder="Search…"
                  className="flex flex-1 mx-8"
                  inputProps={{ "aria-label": "search" }}
                  onChange={handleSearch}
                />
              </Paper>
              <EnhancedTableToolbar
                numSelected={selected.length}
                onAddClick={onAddClick}
                isSuperAdmin={isSuperAdmin}
                noAdd={noAdd}
              />
              <TableContainer className={classes.container}>
                <Table
                  className={classes.table}
                  stickyHeader
                  aria-labelledby="tableTitle"
                  size={"small"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                    headCells={cellHeads}
                  />
                  {!isLoading && (
                    <TableBody>
                      {stableSort(rows, getComparator(order, orderBy))
                        .slice(
                          paginationState.pageNumber * paginationState.pageSize,
                          paginationState.pageNumber *
                            paginationState.pageSize +
                            paginationState.pageSize
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                              style={{ height: 30 }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              {cellHeads.map((cell) => {
                                return (
                                  <TableCell
                                    align={cell.numeric ? "right" : "center"}
                                  >
                                    {row[cell.id]}
                                  </TableCell>
                                );
                              })}
                              <TableCell align="right">
                                <IconButton
                                  color="primary"
                                  aria-label="remove group"
                                  component="span"
                                  onClick={(event) =>
                                    handleEditClick(event, row.id)
                                  }
                                >
                                  <Tooltip title="edit item">
                                    <EditOutlinedIcon />
                                  </Tooltip>
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  aria-label="remove group"
                                  component="span"
                                  disabled={!isSuperAdmin}
                                  onClick={(event) =>
                                    handleDeleteClick(event, row)
                                  }
                                >
                                  <Tooltip title="remove item">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 33 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={paginationState.pageSize}
                page={paginationState.pageNumber}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
            {/* <SpeedDial
              ariaLabel="Add item speed dial"
              icon={<SpeedDialIcon />}
              onClose={toggleAddCompanyUser}
              onOpen={toggleAddCompanyUser}
              open={openAddCompanyUser}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  tooltipTitle={action.name}
                  tooltipOpen
                  icon={action.icon}
                  onClick={() => handleAddClick(action)}
                />
              ))}
            </SpeedDial> */}
          </div>
        </CardContent>
      </Card>
      {showConfirmDelete && (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "100px" }}
          title="Are you sure?"
          onConfirm={() => {
            handleDelete();
            setShowConfirmDelete(false);
          }}
          onCancel={() => {
            setShowConfirmDelete(false);
            setRowToDelete(null);
            setSelected([]);
          }}
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          confirmBtnCssClass={`${buttonStyles.button} ${buttonStyles.danger}`}
          cancelBtnCssClass={`${buttonStyles.button}`}
          showCancel
        >
          <Typography gutterBottom={true} variant="subtitle2">
            Are you sure you want to delete this item?
          </Typography>
        </SweetAlert>
      )}
    </>
  );
};

export default BaseTable;
