import {
  Paper,
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
  makeStyles,
  Fab,
  useTheme,
  Icon,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Zoom from "@material-ui/core/Zoom";
import React, { useState, useEffect, forwardRef } from "react";
import MonitorSelector from "../../components/Monitor/MonitorSelector";
import { BackButton } from "../../components/Common/backButton";
import { useParams } from "react-router";
import {
  saveRole,
  createRole,
  getRoleById,
} from "../../services/Roles/roleService";
import { ScheduleUpdate } from "../../models/ScheduleUpdate";
import { useSnackbar } from "notistack";
import { useValidator } from "../../hooks/validatorHook";
import { monitorItemValidator } from "../../validations/monitorItemValidator";
import { formatter } from "../../helpers/formatter";
import {
  getScheduleUpdateById,
  saveScheduleUpdate,
  createScheduleUpdate,
} from "../../services/ScheduleUpdate/scheduleUpdateService";
import { useAppContext } from "../../context/AppContext";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
//import moment from "moment";
import moment from "moment-timezone";
// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
  fab2: {
    position: "fixed",
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
  },
}));

interface IRoleParams {
  mode: string;
  scheduleUpdateId: string;
}
const ScheduleUpdateEdit = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile, roleHelper } = useAppContext();
  const { children, value, index, ...other } = props;
  const [scheduleUpdate, setscheduleUpdate] = useState<ScheduleUpdate>(
    new ScheduleUpdate()
  );
  const [scheduleDate, setScheduleDate] = useState<Date>(new Date());
  const { mode, scheduleUpdateId } = useParams<IRoleParams>();
  const { enqueueSnackbar } = useSnackbar();

  const { validator, setShouldShowErrors } = useValidator(
    monitorItemValidator,
    scheduleUpdate
  );
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const isSuperAdmin = roleHelper.isSuperAdmin();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    if (mode == "edit") {
      loadMonitor();
    }
    return () => {};
  }, []);

  const loadMonitor = async () => {
    try {
      const { data: menuItemResult } = await getScheduleUpdateById(
        scheduleUpdateId
      );
      if (menuItemResult) {
        setscheduleUpdate(menuItemResult);
      }
    } catch (error) {
      enqueueSnackbar("failed to load menu items group.", { variant: "error" });
    }
  };
  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };

  const onDateChange = (event) => {
    setScheduleDate(event.target.value);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setscheduleUpdate({ ...scheduleUpdate, [name]: value });
  };

  // convert local time to another central
  function convertLocalToCentralTime(localDt, localDtFormat) {
    return moment(`localDt`, localDtFormat).tz("America/Chicago").toDate();
  }

  const onSchedule = async () => {
    //   let scheduleUpdateObj = {...scheduleUpdate};
    //   delete scheduleUpdateObj.id;
    //   let update = Object.assign(new ScheduleUpdate(), scheduleUpdateObj);
    //   update.scheduledDate = new Date(scheduleDate);
    //   console.log(typeof(scheduleDate))
    //   update.scheduleUpdateId = scheduleUpdate.id;
    //   console.log(update);
    try {
      const data = await createScheduleUpdate(scheduleUpdate);

      if (data) {
        enqueueSnackbar("update scheduled successfully.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("error scheduling update.", { variant: "error" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("error scheduling update.", { variant: "error" });
    } finally {
      handleClose();
    }
  };

  const getCurrentDateTime = () => {
    var date = moment().format("YYYY-MM-DD");
    var time = moment().format("HH:mm");
    const currentTime = `${date}T${time}`;
    return currentTime;
  };

  const formatDate = (dateTime) => {
    var date = moment(dateTime).format("YYYY-MM-DD");
    var time = moment().format("HH:mm");
    const currentTime = `${date}T${time}`;
    return currentTime;
  };

  const onSave = async () => {
    console.log("Save");
    console.log(scheduleUpdate);
    scheduleUpdate.width = parseInt(scheduleUpdate.width.toString());
    scheduleUpdate.height = parseFloat(scheduleUpdate.height.toString());
    scheduleUpdate.top = parseFloat(scheduleUpdate.top.toString());
    scheduleUpdate.left = parseFloat(scheduleUpdate.left.toString());
    if (!validateData()) return;
    let success = false;
    try {
      if (Number(scheduleUpdateId) > 0) {
        const { data } = await saveScheduleUpdate(
          scheduleUpdate.id,
          scheduleUpdate
        );
        success = data;
      } else {
        const { data } = await createScheduleUpdate(scheduleUpdate);
        success = data;
      }
      if (success) {
        enqueueSnackbar("successfully saved scheduled update", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("failed to save scheduled update", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("failed to save scheduled update.", { variant: "error" });
    }
  };

  return (
    <>
      <Card>
        <CardHeader
          title={`${mode === "edit" ? "Edit" : "Add"} Scheduled Update`}
          action={
            <>
              <BackButton />
            </>
          }
        />
        <CardContent>
          <Divider />
          <br />
          <Grid container direction="row" spacing={4} md={6}>
            <Grid item xs={12} md={6}>
              <InputLabel id="demo-simple-select-label">
                Update Status
              </InputLabel>
              <Select
                name="updateStatus"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                defaultValue={scheduleUpdate.updateStatus}
                value={scheduleUpdate.updateStatus}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors &&
                      !!validator.result["updateStatus"]
                    : false
                }
                // helperText={
                //   validator
                //     ? validator.shouldShowErrors &&
                //       validator.result["updateStatus"]
                //     : null
                // }
              >
                <MenuItem value={0}>Canceled</MenuItem>
                <MenuItem value={1}>Scheduled</MenuItem>
                <MenuItem value={2}>Pending</MenuItem>
                <MenuItem value={3}>Completed</MenuItem>
                <MenuItem value={999}>Error</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <TextField
                  name="scheduledDate"
                  label="Scheduled Date"
                  variant="outlined"
                  required
                  disabled={!isSuperAdmin}
                  defaultValue={scheduleUpdate.scheduledDate}
                  value={scheduleUpdate.scheduledDate}
                  onChange={onChange}
                  error={
                    validator
                      ? validator.shouldShowErrors && !!validator.result["scheduledDate"]
                      : false
                  }
                  helperText={
                    validator
                      ? validator.shouldShowErrors && validator.result["scheduledDate"]
                      : null
                  }
                /> */}
              <TextField
                id="datetime-local"
                label="Scheduled Date"
                type="datetime-local"
                fullWidth
                defaultValue={formatDate(scheduleUpdate.scheduledDate)}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={onChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="name"
                label="Monitor Item Name (element id)"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                defaultValue={scheduleUpdate.name}
                value={scheduleUpdate.name}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["name"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["name"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="friendlyName"
                label="Friendly name"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                defaultValue={scheduleUpdate.friendlyName}
                value={scheduleUpdate.friendlyName}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors &&
                      !!validator.result["friendlyName"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors &&
                      validator.result["friendlyName"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                name="description"
                label="Description"
                variant="outlined"
                fullWidth
                required
                disabled={!isSuperAdmin}
                multiline
                rows={4}
                defaultValue={scheduleUpdate.description}
                value={scheduleUpdate.description}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors &&
                      !!validator.result["description"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors &&
                      validator.result["description"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                name="value"
                label="Value"
                variant="outlined"
                required
                fullWidth
                multiline
                rows={3}
                defaultValue={scheduleUpdate.value}
                value={scheduleUpdate.value}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["value"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["value"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <MonitorSelector
                name="monitorId"
                label="Monitor"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                defaultValue={scheduleUpdate.monitorId}
                value={scheduleUpdate.monitorId}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors &&
                      !!validator.result["monitorId"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors &&
                      validator.result["monitorId"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="h6">Position and Styling Details</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="class"
                label="Class"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                defaultValue={scheduleUpdate.class}
                value={scheduleUpdate.class}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["class"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["class"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="width"
                label="Width"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                multiline
                value={scheduleUpdate.width}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["width"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["width"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="height"
                label="Height"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                multiline
                value={scheduleUpdate.height}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["height"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["height"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="top"
                label="Top"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                multiline
                value={scheduleUpdate.top}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["top"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["top"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                name="left"
                label="Left"
                variant="outlined"
                required
                disabled={!isSuperAdmin}
                multiline
                value={scheduleUpdate.left}
                onChange={onChange}
                error={
                  validator
                    ? validator.shouldShowErrors && !!validator.result["left"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["left"]
                    : null
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={scheduleUpdate.active}
                    onChange={onChange}
                    name="active"
                    color="primary"
                    disabled={!isSuperAdmin}
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
          <br />
          {!isMobile && (
            <Grid item xs={12} md={6} lg={6}>
              {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  <span className="hidden sm:flex">
                    <ScheduleIcon />
                    schedule
                  </span>
                </Button>
                &nbsp; */}
              <Button variant="contained" color="primary" onClick={onSave}>
                <span>
                  <SaveIcon />
                  save
                </span>
              </Button>
            </Grid>
          )}
          {isMobile && (
            <>
              {/* <Zoom
                  key="primary"
                  in={value === index}
                  timeout={transitionDuration}
                  style={{
                    transitionDelay: `${
                      value === index ? transitionDuration.exit : 0
                    }ms`,
                  }}
                  unmountOnExit
                >
                  <Fab
                    aria-label="Add"
                    className={classes.fab2}
                    color="primary"
                    onClick={handleClickOpen}
                  >
                    <ScheduleIcon />
                  </Fab>
                </Zoom> */}
              <Zoom
                key="primary"
                in={value === index}
                timeout={transitionDuration}
                style={{
                  transitionDelay: `${
                    value === index ? transitionDuration.exit : 0
                  }ms`,
                }}
                unmountOnExit
              >
                <Fab
                  aria-label="Add"
                  className={classes.fab}
                  color="primary"
                  onClick={onSave}
                >
                  <SaveIcon />
                </Fab>
              </Zoom>
            </>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Schedule an update?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Schedule an update by selecting a data time and hitting save
          </DialogContentText>
          <TextField
            id="datetime-local"
            label="Schedule Update"
            type="datetime-local"
            fullWidth
            defaultValue={getCurrentDateTime()}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSchedule} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduleUpdateEdit;
