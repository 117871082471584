import axios from "axios";
import { apiUrl } from "../../config/appConfig";
import { AppSettings } from "../../models/AppSettings";

const endpoint = `${apiUrl}/api/AppSettings`;

export const getAppSettings = async () => {
  return await axios.get(`${endpoint}`);
};

export const getAppSetting = (AppSettingId: number) => {
  return axios.get(`${endpoint}/${AppSettingId}`);
};

export const addAppSetting = async (newAppSetting: AppSettings) => {
  return await axios.post(`${endpoint}`, newAppSetting);
};

export const updateAppSetting = (
  AppSettingId: number,
  updatedAppSetting: AppSettings
) => {
  return axios.put(`${endpoint}/${AppSettingId}`, updatedAppSetting);
};

export const deleteAppSetting = (AppSettingId: number) => {
  return axios.delete(`${endpoint}/${AppSettingId}`);
};
