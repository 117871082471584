import { Accordion, AccordionSummary, AccordionDetails, Typography, CardHeader, Card, CardContent, CircularProgress, Grid, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getGroupContactsByGroupId } from '../../services/GroupContacts/groupContactService';
import { getContacts } from '../../services/Contacts/contactService';
import displayTables from '../Tables/tables';
import GroupContactTable from '../GroupContact/GroupContactTable';
import { BackButton } from '../Common/backButton';
import { Group } from '../../models/Group';
import { getContact } from '../../services/Contacts/contactService';
import { getGroup, saveGroup } from '../../services/Groups/groupService';
import { Title } from '../../helpers/stringExtensions';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const GroupDetails = () => {
    const [loading, setLoading] = useState(false);
    const [contactData, setContactData] = useState([]);
    const [group, setGroup] = useState(new Group());
    const { mode, groupId } = useParams();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    let history = useHistory();
    let table = displayTables.find(t => t.tableName === "groupcontacts");


    useEffect(() => {
        if (mode == "edit" && groupId > 0) {
            loadGroup();
        }
        loadData();
        return () => { };
    }, [])

    const headCells = [
        { id: 'name', numeric: false, disablePadding: true, label: 'FullName' },
        { id: 'firstName', numeric: false, disablePadding: true, label: 'FirstName' },
        { id: 'lastName', numeric: true, disablePadding: false, label: 'LastName' },
        { id: 'phoneNumber', numeric: true, disablePadding: false, label: 'PhoneNumber' },
        { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
    ]

    const loadGroup = async () => {
        try {
            let { data: loadedGroup } = await getGroup(groupId);
            setGroup(loadedGroup);
        }
        catch (error) {
            console.log(error);
        }
    }


    const loadData = async () => {
        setLoading(true);
        try {
            let { data: contactData } = await getContacts();
            setContactData(contactData);
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    const handleSave = async() => {
        setLoading(true);
        try {
            group.clientId = 1;
            const { data: success } = await saveGroup(group.id, group);

            if (success) {
                enqueueSnackbar('successfully saved.', { variant: "success" });
                history.goBack();
            }
            else {
                enqueueSnackbar('failed to save group.', { variant: "error" });
            }
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('failed to add group.', { variant: "error" });
        }
        finally {
            setLoading(false);
        }
    }

    const handleChange = (event) => {
        console.log(event);
        let value = event.currentTarget.value;
        let key = event.currentTarget.id;
        group[key] = value;
        setGroup({ ...group });
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={Title(`${mode} Group`)}
                    action={
                        <BackButton />
                    }
                />
                <CardContent>
                    <Card>
                        <CardContent>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="name"
                                label="Group Name"
                                value={group.name}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="description"
                                label="Group Description"
                                value={group.description}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                            >
                                Save
                                    </Button>
                        </Grid>
                            </Grid>
                        </CardContent>
                     </Card>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Group Contacts</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {loading && <CircularProgress />}
                    {!loading &&
                        <GroupContactTable tableTitle={"contacts"} contacts={contactData} headCells={headCells} />
                    }
                </AccordionDetails>
                    </Accordion>
                </CardContent>
                </Card>
        </>
    )


}

export default GroupDetails;