import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationState } from "../../../store/pagination/paginationState";
import { searchScheduleUpdates } from "../../../services/ScheduleUpdate/scheduleUpdateService";

interface ISearchProp {
  storeId: number;
  searchText: string;
}

const paginationState = new PaginationState(
  "program-management/scheduleUpdates/log/pagination",
  {}
);

export const getItemsAsync = createAsyncThunk(
  "scheduleUpdates/getScheduleUpdates",
  async (search: ISearchProp, { dispatch, rejectWithValue }) => {
    const { storeId, searchText } = search;
    return searchScheduleUpdates(searchText, storeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

const scheduleUpdatesSlice = createSlice({
  name: "scheduleUpdates",
  initialState: {
    items: [],
    pagination: paginationState.getInitialState,
  },
  reducers: {
    ...paginationState.reducers,
  },
  extraReducers: (builder) => {
    builder.addCase(getItemsAsync.fulfilled, (state, actions) => {
      state.items = actions.payload;
    });
  },
});

export const { setPageSize, setPageNumber } = scheduleUpdatesSlice.actions;

export const scheduleUpdates = scheduleUpdatesSlice.reducer;
