/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Group } from "../../models/Group";
import { MonitorItem } from "../../models/MonitorItem";
//import { getMonitorItemById } from "../../services/Monitor/monitorService";
import { getMonitorItem } from "../../services/MonitorItem/monitorItemService";
import { useMenuItem } from "../../components/MenuItem/hooks/useMenuItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 1000,
      "& > * + *": {
        marginTop: theme.spacing(3),
      },
    },
  })
);

interface AutoCompleteMultiProps {
  data: Group[];
  labelOption: string;
}

function not(a, b) {
  const bIds = b.map((x) => x.id);
  return a.filter((value) => bIds.indexOf(value.id) === -1);
}

export default function MonitorItemMultiSelector({ selectedItems, onMonitorItemsChange }) {
  const {
    menuItem,
    setMenuItem,
    // validator,
    // setShouldShowErrors,
    availableMonitorItems,
    setAvailableMonitorItems,
    monitorItems,
    setMonitorItems,
    selectedItemValues,
    setSelectedItems,
  } = useMenuItem();

  const classes = useStyles();
  // const [availableMonitorItems, setAvailableMonitorItems] = useState<Array<MonitorItem>>([]);
  // const [monitorItems, setMonitorItems] = useState<Array<MonitorItem>>([]);
  // const [selectedItemValues, setSelectedItems] = useState<Array<MonitorItem>>(selectedItems);
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setSelectedItems(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    var available = not(monitorItems, selectedItemValues);
    setAvailableMonitorItems(not(monitorItems, selectedItemValues));
  }, [selectedItemValues]);

  const loadData = async () => {
    //let { data: items } = await getMonitorItem();
    const items = [];
    setMonitorItems(items);
    setAvailableMonitorItems(items);
  };
  const handleChange = (event: any, value: Array<MonitorItem>) => {
      let updatedMenuItem = {...menuItem};
      updatedMenuItem.monitorItems = value;
    setMenuItem({...menuItem, monitorItems: value});
    setSelectedItems(value);
    onMonitorItemsChange(value);
  };
  const handleClose = (event: any) => {
    let eventHandled = event;
  };

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-standard"
        options={availableMonitorItems}
        getOptionLabel={(option) => option["name"]}
        value={selectedItemValues}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={"Monitor Items"}
            placeholder="Select Items..."
          />
        )}
        onChange={handleChange}
        onClose={handleClose}
      />
    </div>
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
