import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector, itemSelector } from "./store/storesSelector";
import { getItemsAsync, setSelectedStore } from "./store/storesSlice";
import { makeStyles, fade } from "@material-ui/core/styles";
import {
  getStoreMonitorsAsync,
  setSelectedMonitor,
} from "../Monitor/store/monitorSlice";

const useStyles = makeStyles((theme) => ({
  inputStyles: {
    fontSize: 12,
    color: "white",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  root: { color: "white !important" },
  notchedOutline: {
    borderColor: "white !important",
  },
  formLabel: {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  },
}));

const StoreSelector = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalSelectedStore = useSelector(selectedStoreSelector, shallowEqual);
  const globalStores = useSelector(itemSelector, shallowEqual);

  useEffect(() => {
    dispatch(getItemsAsync());
  }, []);

  const handleChange = async (event: any, values: any) => {
      const {payload } = await dispatch(setSelectedStore(values));
    dispatch(getStoreMonitorsAsync(values.id));
    dispatch(setSelectedMonitor({ id: 0, name: "ALL" }));
    console.log(values);
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      options={globalStores}
      getOptionLabel={(option) => option.name}
      style={{ width: 300, color: "white" }}
      size="small"
      renderInput={(params) => (
        <TextField {...params} label="Selected Store" variant="outlined" />
      )}
      value={globalSelectedStore}
      onChange={handleChange}
    />
  );
};

export default StoreSelector;
