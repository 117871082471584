import { createSelector } from "reselect";

const Users = (state) => state.users;

export const itemSelector = createSelector(
    Users,
    (Users) => Users.items
  );

export const paginationSelector = createSelector(
    Users,
    (Users) => Users.pagination
);