import React, { useEffect } from "react";
import { CustomTable } from "../Common/CustomTable";
import {
  getMenuItems,
  deleteMenuItem,
  searchMenu,
} from "../../services/MenuItems/menuItemService";
import { useHistory } from "react-router";
import MenuItemLogMobile from "./MenuItemLogMobile";
import { useAppContext } from "../../context/AppContext";
import StoreSelector from "../Store/StoreSelector";

const menuItemColumns = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  { id: "price", numeric: true, disablePadding: false, label: "Price" },
  { id: "calories", numeric: true, disablePadding: false, label: "Calories" },
  { id: "extra", numeric: false, disablePadding: false, label: "Extra" },
];

const MenuItemLog = () => {
  const history = useHistory();
  const { isMobile } = useAppContext();

  const handleAdd = () => {
    history.push("/pages/menuItems/add/0");
  };
  const handeEdit = (id: number) => {
    history.push(`/pages/menuItems/edit/${id}`);
  };
  const getMenuItemsForTable = async () => {
    const { data: items } = await getMenuItems();
    console.log("items");
    console.log(items);
    return items.map((item) => {
      return { ...item, price: item.price.toFixed(2) };
    });
  };
  return (
    <>
      {isMobile ? (
        <MenuItemLogMobile />
      ) : (
        <CustomTable
          tableTitle="MenuItems"
          getTableData={() => getMenuItemsForTable()}
          searchTableData={searchMenu}
          cellHeads={menuItemColumns}
          handleAdd={handleAdd}
          handeEdit={handeEdit}
          deleteData={deleteMenuItem}
        />
      )}
    </>
  );
};

export default MenuItemLog;
