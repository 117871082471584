import React, { useEffect } from "react";
import { removeScheduleUpdate } from "../../services/ScheduleUpdate/scheduleUpdateService";
import { CustomTable } from "../Common/CustomTable";
import { useAppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import ScheduleUpdateMobile from "./ScheduleUpdateMobile";
import moment from "moment";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";
import {
  itemSelector,
  paginationSelector,
} from "../ScheduledUpdate/store/scheduleUpdatesSelector";
import {
  setPageSize,
  setPageNumber,
  getItemsAsync,
} from "./store/scheduleUpdatesSlice";
import StoreSelector from "../Store/StoreSelector";
const ScheduleUpdatesColumns = [
  { id: "name", numeric: false, disablePadding: true, label: "Monitor Name" },
  {
    id: "friendlyName",
    numeric: false,
    disablePadding: true,
    label: "Friendly Name",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  { id: "value", numeric: false, disablePadding: true, label: "Value" },
  { id: "class", numeric: false, disablePadding: true, label: "Class" },
  { id: "active", numeric: false, disablePadding: true, label: "Active" },
  {
    id: "updateStatus",
    numeric: false,
    disablePadding: true,
    label: "Update Status",
  },
  {
    id: "scheduledDate",
    numeric: false,
    disablePadding: true,
    label: "Schedule Date",
  },
];

const ScheduleUpdateLog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, roleHelper } = useAppContext();
  const paginationState = useSelector(paginationSelector, shallowEqual);
  const scheduleUpdates = useSelector(itemSelector, shallowEqual);
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);
  const pagination = {
    paginationState,
    setPageSize,
    setPageNumber,
  };

  const handleAdd = () => {
    history.push("/ScheduleUpdates/add/0");
  };
  const handeEdit = (id: number) => {
    history.push(`/ScheduleUpdates/edit/${id}`);
  };

  useEffect(() => {
    const selectedStoreId = selectedStore ? selectedStore.id : 0;
    dispatch(getItemsAsync({ storeId: selectedStoreId, searchText: "" }));
  }, [selectedStore]);

  return (
    <>
    <StoreSelector />
      {isMobile ? (
        <ScheduleUpdateMobile rows={scheduleUpdates}/>
      ) : (
        <CustomTable
          tableTitle="ScheduleUpdates"
          rows={scheduleUpdates}
          cellHeads={ScheduleUpdatesColumns}
          handleAdd={handleAdd}
          handeEdit={handeEdit}
          deleteData={removeScheduleUpdate}
          isSuperAdmin={roleHelper.isSuperAdmin()}
          noAdd
          pagination={pagination}
        />
      )}
    </>
  );
};

export default ScheduleUpdateLog;
