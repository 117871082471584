export class PaginationState {
  constructor(name, extraReducers, initialState) {
    this.name = name;
    this.extraReducers = extraReducers;
    this.initialState = initialState;
  }
  getInitialState = {
    pageSize: 10,
    pageNumber: 0,
    totalPages: 0,
    totalRecords: 0,
    ...this.initialState,
  };
  reducers = {
    setPageSize: (state, action) => {
      state.pagination.pageSize = action.payload;
      state.pagination.pageNumber = 0;
    },
    setPageNumber: (state, action) => {
      state.pagination.pageNumber = action.payload;
    },
  };
  extraReducers = {
    ...this.extraReducers,
  };
}