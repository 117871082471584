import { createSlice } from "@reduxjs/toolkit";

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState: {
    searchText: "",
  },
  reducers: {
    setGlobalSearch: (state, action) => {
      state.searchText = action.payload;
    },
  },
});

export const { setGlobalSearch } = globalSearchSlice.actions;

export const globalSearch = globalSearchSlice.reducer;
