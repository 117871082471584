import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader } from "@material-ui/core";
import {
  getUsers,
  searchUsers,
  deleteUser,
} from "../../services/Users/userService";
import { CustomTable } from "../Common/CustomTable";
import BaseTable from "../Common/BaseTable";
import { useAppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import UserMobileLog from "./UserMobileLog";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";
import { setPageSize, setPageNumber, getItemsAsync } from "./store/userSlice";
import { itemSelector, paginationSelector } from "../User/store/userSelector";

const userColumns = [
  { id: "userName", numeric: false, disablePadding: true, label: "User Name" },
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: true,
    label: "First Name",
  },
];

const UserLog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, roleHelper } = useAppContext();
  const paginationState = useSelector(paginationSelector, shallowEqual);
  const users = useSelector(itemSelector, shallowEqual);
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);

  const pagination = {
    paginationState,
    setPageSize,
    setPageNumber,
  };
  const handleAdd = () => {
    history.push("/users/add/0");
  };
  const handeEdit = (id: number) => {
    history.push(`/users/edit/${id}`);
  };

  useEffect(() => {
    dispatch(getItemsAsync({ storeId: selectedStore.id, searchText: "" }));
  }, [selectedStore]);

  return (
    <>
      {isMobile ? (
        <UserMobileLog rows={users} />
      ) : (
        <CustomTable
          tableTitle="users"
          rows={users}
          cellHeads={userColumns}
          handleAdd={handleAdd}
          handeEdit={handeEdit}
          deleteData={deleteUser}
          isSuperAdmin={roleHelper.isSuperAdmin()}
          pagination={pagination}
        />
      )}
    </>
  );
};

export default UserLog;
