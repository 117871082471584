import { Client } from './Client';;

export class Contact {
    public id: number = 0;
    public clientId: number = 0;
    public firstName: string = '';
    public lastName: string = '';
    public phoneNumber: string = '';
    public email: string = '';
    public isDeleted: boolean = false;
    public createdBy: string = 'System';
    public createdate: string = new Date().toISOString();
    public name: string = '';

    public client: Client = new Client();
}