import axios from "axios";
import { apiUrl } from "../../config/appConfig";
import { MonitorItem } from "../../models/MonitorItem";

const endpoint = `${apiUrl}/api/MonitorItem`;

export const getMonitorItem = async (storeId: number) => {
  return await axios.get(`${endpoint}/${storeId}/store`);
};

export const getMonitorItemById = async (id: string) => {
  return await axios.get(`${endpoint}/${id}`);
  
};
export const saveMonitorItem = async (id: number, menuItem: MonitorItem) => {
  return await axios.put(`${endpoint}/${id}`, menuItem);
};

export const createMonitorItem = async (menuItem: MonitorItem) => {
    return await axios.post(`${endpoint}`, menuItem);
};

export const deleteMonitorItem = async (id: number) =>  {
    return await axios.delete(`${endpoint}/${id}`);
};

export const searchMonitorItems = async (searchText: string, selectedMonitorId: number, storeId: number) =>{
    return await axios.get(`${endpoint}/store/${storeId}/monitor/${selectedMonitorId}/search/${searchText}`)
}

export const getMonitorItemsByMonitorId = async (monitorId: number) => {
    return await axios.get(`${endpoint}/${monitorId}/monitor`)
}

export const searchStoreMonitorItem = async (storeId: number, searchText:string) => {
  return await axios.get(`${endpoint}/search/${searchText}`)
}

export const getChalkboardMonitorByStoreId = async (storeId: number) => {
    return await axios.get(`${endpoint}/chalkboardmonitor/${storeId}`)
}

export const saveMonitorItems = async (monitorItems: Array<MonitorItem>) => {
    return await axios.put(`${endpoint}/items`, { monitorItems: monitorItems });
}