export class Monitor {
  public id: number = 0;
  public name: string = "";
  public friendlyName: string = "";
  public description: string = "";
  public width: number =0;
  public height: number =0;
  public storeName: string = "";
  public storeId: number = 0;
  public isChalkboard: boolean = false;
  public backgroundImagePath: string = "";
}
