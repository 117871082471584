import React from 'react';
import { Tooltip, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const BackButton = () => {
    let history = useHistory();

    const goBack = () => {
        history.goBack();
    }

    return (
        <Tooltip title="Go back" arrow>
            <Button
                variant="contained"
                color="primary"
                onClick={goBack}
            >
                <ArrowBackIcon />
            </Button>
        </Tooltip>
    )
}