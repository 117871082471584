import { createSelector } from "reselect";

const Monitors = (state) => state.monitors;

export const itemSelector = createSelector(
  Monitors,
  (Monitors) => Monitors.items
);

export const monitorSelector = createSelector(
  Monitors,
  (Monitors) => Monitors.selectedMonitor
);

export const availableMonitorSelector = createSelector(
  Monitors,
  (Monitors) => Monitors.availableMonitors
);

export const paginationSelector = createSelector(
  Monitors,
  (Monitors) => Monitors.pagination
);

export const searchSelector = createSelector(
  Monitors,
  (Monitors) => Monitors.search
);

export const monitorItemsSelector = createSelector(
    Monitors,
    (Monitors) => Monitors.monitorItems
);

export const selectedMonitorItemSelector = createSelector(
    Monitors,
    (Monitors) => Monitors.selectedMonitorItem
)

export const requestsItemSelector = createSelector(
    Monitors,
    (Monitors) => Monitors.requests
)

export const currentViewStatusSelector = createSelector(
    Monitors,
    (Monitors) => Monitors.currentViewStatus
)