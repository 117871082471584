
export class Client {
    public id: number = 0;
    public name: string = '';
    public phone: string = '';
    public address: string = '';
    public address2: string = '';
    public city: string = '';
    public state: string = '';

    //relationships

}