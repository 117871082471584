import React from "react";
import { Route } from "react-router";
import Login from "../Login/LoginPage";
import ResetPassword from "../ResetPassword/ResetPassword";
import ResetPasswordRequest from "../ResetPassword/ResetPasswordRequest";
import { PAGES } from "../../shared/urlTemplates";
const Pages = () => {
  return (
    <>
      <Route exact path="/pages/login" component={Login} />
      <Route
        exact
        path="/pages/resetpassword/:userId/:token"
        component={ResetPassword}
      />
      <Route
        exact
        path={PAGES.FORGOTPASSWORD}
        component={ResetPasswordRequest}
      />
    </>
  );
};

export default Pages;
