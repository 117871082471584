import {
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
  makeStyles,
  Fab,
  useTheme,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import SaveIcon from "@material-ui/icons/Save";
import Zoom from "@material-ui/core/Zoom";
import ScheduleIcon from "@material-ui/icons/Schedule";
import React, { useState, useEffect } from "react";
// import MonitorSelector from "./MonitorSelector";
import { BackButton } from "../../components/Common/backButton";
import { useParams } from "react-router";
import {
  saveRole,
  createRole,
  getRoleById,
} from "../../services/Roles/roleService";
import { Monitor } from "../../models/Monitor";
import { MonitorItem } from "../../models/MonitorItem";
import { useSnackbar } from "notistack";
import { useValidator } from "../../hooks/validatorHook";
import { monitorValidator } from "../../validations/monitorValidator";
import { formatter } from "../../helpers/formatter";
import {
  getMonitorById,
  saveMonitor,
  createMonitor,
} from "../../services/Monitor/monitorService";
import { requestSchduleUpdates } from "../../services/ScheduleUpdate/scheduleUpdateService";
import { useAppContext } from "../../context/AppContext";
import FileUploader from "../../components/Common/FileUpload/FileUploader";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";
import MonitorViewComponent from "../MonitorViewer/MonitorViewComponent";
import { saveMonitorItemsAsync, setMonitorItems, setSelectedMonitorItem, getMonitorItemsAsync, setCurrentViewStatus } from "./store/monitorSlice";
import { monitorItemsSelector, selectedMonitorItemSelector, currentViewStatusSelector } from "./store/monitorSelector";
import moment from "moment-timezone";
import RequestListDialog from "./RequestListDialog";
import { openDialog } from "../../store/applicationSlice";
import DirectionalPad from "../Common/DirectionalPad/DirectionalPad";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface IRoleParams {
  mode: string;
  monitorId: string;
}

const MonitorEdit = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const monitorItems = useSelector(monitorItemsSelector, shallowEqual);
  const currentViewStatus = useSelector(currentViewStatusSelector, shallowEqual);
  const { isMobile, roleHelper } = useAppContext();
  const { children, value, index, ...other } = props;
  const [monitor, setmonitor] = useState<Monitor>(new Monitor());
  const { mode, monitorId } = useParams<IRoleParams>();
  const { enqueueSnackbar } = useSnackbar();
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);
  const selectedStoreId = selectedStore ? selectedStore.id : 0;
  const itemSelected = useSelector(selectedMonitorItemSelector, shallowEqual)
    const [scheduleDate, setScheduleDate] = useState<Date>(new Date());
    const [incrementValue, setIncrementValue] = useState(10);
  useEffect(() => {
    const updatedMonitor = { ...monitor };
    setmonitor({ ...updatedMonitor, storeId: selectedStoreId });
  }, [selectedStore]);

  const { validator, setShouldShowErrors } = useValidator(
    monitorValidator,
    monitor
  );

  const isSuperAdmin = roleHelper.isSuperAdmin();
  const isAdmin = roleHelper.isAdmin();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    if (mode == "edit") {
      loadMonitor();
    }
    return () => {};
  }, []);

  const loadMonitor = async () => {
    try {
      const { data: menuResult } = await getMonitorById(monitorId);
      if (menuResult) {
        setmonitor(menuResult);
      }
    } catch (error) {
      enqueueSnackbar("failed to load menu items group.", { variant: "error" });
    }
  };
  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setmonitor({ ...monitor, [name]: value });
  };

  const onFileChange = (file: string) => {
    setmonitor({ ...monitor, backgroundImagePath: file });
  };
  const onSave = async () => {
    if (!validateData()) return;
    let success = false;
    try {
      if (Number(monitorId) > 0) {
          const { data } = await saveMonitor(monitor.id, { ...monitor, width: Number(monitor.width), height: Number(monitor.height)});
        success = data;
      } else {
        const { data } = await createMonitor(monitor);
        success = data;
      }
        if (success) {
            const updatedMonitorItems = monitorItems.map(item => {
                return {...item, id: item.monitorItemId || item.id}
            })
            dispatch(saveMonitorItemsAsync(updatedMonitorItems));
        enqueueSnackbar("successfully saved monitor item", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("failed to save monitor item", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("failed to save monitor item.", { variant: "error" });
    }
    };

    const handleReset = () => {
        dispatch(setSelectedMonitorItem({}));
        dispatch(setCurrentViewStatus("Current View"));
        dispatch(getMonitorItemsAsync(Number(monitorId)));
    }

    const handleScreenChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        updateMonitorItems(name, value);
    };

    const updateMonitorItems = (prop, value) => {
        const itemIndex = monitorItems.findIndex(x => x.id === itemSelected.id);

        const updatedMonitorItems = [
            ...monitorItems.slice(0, itemIndex),
            { ...itemSelected, [prop]: value },
            ...monitorItems.slice(itemIndex + 1),
        ];
        dispatch(setSelectedMonitorItem({ ...itemSelected, [prop]: value }));
        dispatch(setMonitorItems(updatedMonitorItems));
    }


    const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onDateChange = (event) => {
    setScheduleDate(event.target.value);
  };

  const getCurrentDateTime = () => {
    var date = moment().format("YYYY-MM-DD");
    var time = moment().format("HH:mm");
    const currentTime = `${date}T${time}`;
    return currentTime;
  };




    const handleLeftClick = () => {
        updateMonitorItems("left", itemSelected.left - 10);
    };

    const handleRightClick = () => {
        updateMonitorItems("left", itemSelected.left + 10);
    };

    const handleUpClick = () => {
        updateMonitorItems("top", itemSelected.top - 10);
    };

    const handleDownClick = () => {
        updateMonitorItems("top", itemSelected.top + 10);
    };

    const onSchedule = async () => {
        let updatedMonitorItems = [...monitorItems];
        let utcDate = new Date(new Date(scheduleDate).toUTCString());
        const result = await requestSchduleUpdates({
            monitorId: Number(monitorId),
            scheduleDate: utcDate,
            monitorItems: updatedMonitorItems
        })
        if (result) {
            enqueueSnackbar("successfully scheduled updates", {
                variant: "success",
            });
        }

   handleClose();
    }

    const handleViewRequests = () => {
        dispatch(
            openDialog({
                children: (
                    <RequestListDialog  
                        monitorId={monitorId}
                    />
                ),
            })
        );
    }

    const handleIncrementChange = ({ currentTarget }) => {
        const { value } = currentTarget;
        setIncrementValue(value);
    }

  return (
    <>
      <Card>
        <CardHeader
          title={`${mode === "edit" ? "Edit" : "Add"} Monitor Items`}
          action={
              <div className="flex">
                  {!isMobile && (
                      <>
                          <div className="pr-4">
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleViewRequests}
                              >
                                  <span className="hidden sm:flex">
                                      <ScheduleIcon />
                                      View Pending Requests
                                  </span>
                              </Button>
                          </div>
                      <div className="pr-4">
                      <Button
                            variant="contained"
                            color="primary"
                            onClick={handleClickOpen}
                          >
                            <span className="hidden sm:flex">
                              <ScheduleIcon />
                              schedule
                            </span>
                          </Button>
                          </div>
                          {isSuperAdmin && <div className="pr-4">
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={onSave}
                              >
                                  Save
                              </Button>
                          </div>}
                      <div className="pr-4">
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={handleReset}
                                  disabled={!isSuperAdmin && !isAdmin}
                              >
                                  Reset to Current
                              </Button>
                      </div>
                      </>
                  )}
                  <div className="pr-4">
                      <BackButton />
                  </div>
            </div>
          }
        />
        <CardContent>
          <Divider />
          <br />
          <div className="flex">
            <div className="flex-2">
              <Grid container direction="row" spacing={1} md={12}>
                 <Grid container direction="row" spacing={1} md={12}>
                <Grid item md={12}>
                    <Typography variant="subtitle1">Monitor Details</Typography>
                </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="id"
                      label="Monitor Instance Id"
                      variant="outlined"
                      required
                      disabled
                      defaultValue={monitor.id}
                      value={monitor.id}
                      error={
                        validator
                          ? validator.shouldShowErrors &&
                            !!validator.result["id"]
                          : false
                      }
                      helperText={
                        validator
                          ? validator.shouldShowErrors && validator.result["id"]
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="storeId"
                      label="Store Id"
                      variant="outlined"
                      required
                      disabled
                      defaultValue={monitor.storeId}
                      value={monitor.storeId}
                      error={
                        validator
                          ? validator.shouldShowErrors &&
                            !!validator.result["storeId"]
                          : false
                      }
                      helperText={
                        validator
                          ? validator.shouldShowErrors &&
                            validator.result["storeId"]
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="name"
                      label="Name"
                      variant="outlined"
                      required
                      disabled={!isSuperAdmin}
                      defaultValue={monitor.name}
                      value={monitor.name}
                      onChange={onChange}
                      error={
                        validator
                          ? validator.shouldShowErrors &&
                            !!validator.result["name"]
                          : false
                      }
                      helperText={
                        validator
                          ? validator.shouldShowErrors &&
                            validator.result["name"]
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      name="description"
                      label="Description"
                      variant="outlined"
                      disabled={!isSuperAdmin}
                      defaultValue={monitor.description}
                      value={monitor.description}
                      onChange={onChange}
                      error={
                        validator
                          ? validator.shouldShowErrors &&
                            !!validator.result["description"]
                          : false
                      }
                      helperText={
                        validator
                          ? validator.shouldShowErrors &&
                            validator.result["description"]
                          : null
                      }
                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <TextField
                                          name="width"
                                          label="Width"
                                          variant="outlined"
                                          required
                                          disabled={!isSuperAdmin}
                                          defaultValue={monitor.width}
                                          value={monitor.width}
                                          onChange={onChange}
                                          error={
                                              validator
                                                  ? validator.shouldShowErrors &&
                                                  !!validator.result["width"]
                                                  : false
                                          }
                                          helperText={
                                              validator
                                                  ? validator.shouldShowErrors &&
                                                  validator.result["width"]
                                                  : null
                                          }
                                      />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <TextField
                                          name="height"
                                          label="Height"
                                          variant="outlined"
                                          disabled={!isSuperAdmin}
                                          defaultValue={monitor.height}
                                          value={monitor.height}
                                          onChange={onChange}
                                          error={
                                              validator
                                                  ? validator.shouldShowErrors &&
                                                  !!validator.result["height"]
                                                  : false
                                          }
                                          helperText={
                                              validator
                                                  ? validator.shouldShowErrors &&
                                                  validator.result["height"]
                                                  : null
                                          }
                                      />
                                  </Grid>
                                  
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={monitor.isChalkboard}
                          onChange={onChange}
                          name="isChalkboard"
                          color="primary"
                          disabled={!isSuperAdmin}
                        />
                      }
                      label="Active"
                    />
                  </Grid>
                  {isSuperAdmin && <Grid item xs={12} md={6}>
                    <FileUploader
                      name="backgrounImagePath"
                      value={monitor.backgroundImagePath}
                      onChange={onFileChange}
                      onUpload={() => {}}
                      componentKey={monitor.id}
                                          />
                  </Grid>}
                </Grid>               
               </Grid>
                <Grid container direction="row" spacing={1} md={12}>
                              <Grid item md={12}>
                                  <Divider/>
                              </Grid>
                              <Grid item md={12}>
                                  <Typography variant="subtitle1">Selected Monitor Item </Typography>
                              </Grid>
                             
                    <Grid item md={12}>
                        <TextField
                            name="value"
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            label={itemSelected?.friendlyName || ""}
                            value={itemSelected?.value || ""}
                            onChange={handleScreenChange} />
                              </Grid>
                              {/*<Grid item xs={8} md={4}>*/}
                              {/*    <FormControl>*/}
                              {/*        <InputLabel id="increment-select-label">Increment Value</InputLabel>*/}
                              {/*          <Select*/}
                              {/*            labelId="increment-select-label"*/}
                              {/*            id="increment-select"*/}
                              {/*            value={incrementValue}*/}
                              {/*            onChange={handleIncrementChange}*/}
                              {/*          >*/}
                              {/*            <MenuItem value={1}>1</MenuItem>*/}
                              {/*            <MenuItem value={5}>5</MenuItem>*/}
                              {/*            <MenuItem value={10}>10</MenuItem>*/}
                              {/*            <MenuItem value={50}>50</MenuItem>*/}
                              {/*            <MenuItem value={100}>100</MenuItem>*/}
                              {/*          </Select>*/}
                              {/*    </FormControl>*/}
                              {/*</Grid>*/}
                    <Grid item xs={8} md={4}>
                        <DirectionalPad
                            handleLeftClick={handleLeftClick}
                            handleRightClick={handleRightClick}
                            handleUpClick={handleUpClick}
                            handleDownClick={handleDownClick}
                        />
                    </Grid>
                </Grid>
            </div>
            <div className="flex-4">
              <Grid item md={8}>
                <Typography align="center">{currentViewStatus}</Typography>
                <MonitorViewComponent
                  storeId={selectedStoreId}
                  monitorId={Number(monitorId)}
                  width={Number(monitor.width)}
                  height={Number(monitor.height)}
                   />
              </Grid>
            </div>
          </div>
          <br />
          {isMobile && (
            <>
              <Zoom
                key="primary"
                in={value === index}
                timeout={transitionDuration}
                style={{
                  transitionDelay: `${
                    value === index ? transitionDuration.exit : 0
                  }ms`,
                }}
                unmountOnExit
              >
                <Fab
                  aria-label="Add"
                  className={classes.fab}
                  color="primary"
                  disabled={!isSuperAdmin}
                  onClick={onSave}
                >
                  <SaveIcon />
                </Fab>
              </Zoom>
            </>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Schedule an update?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Schedule an update by selecting a data time and hitting save
          </DialogContentText>
          <TextField
            id="datetime-local"
            label="Schedule Update"
            type="datetime-local"
            fullWidth
            defaultValue={getCurrentDateTime()}
            // className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={onDateChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSchedule} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MonitorEdit;
