import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { History } from "history";
import { ApplicationState, reducers } from "./";
import { monitors } from "../components/Monitor/store/monitorSlice";
import { monitorItems } from "../components/MonitorItem/store/monitorItemSlice";
import { users } from "../components/User/store/userSlice";
import { scheduleUpdates } from "../components/ScheduledUpdate/store/scheduleUpdatesSlice";
import { roles } from "../components/Role/store/rolesSlice";
import { stores } from "../components/Store/store/storesSlice";
import { globalSearch } from "./global-search/globalSearchSlice";
import { applicationSettings } from "./applicationSettingsSlice";
import { application } from "./applicationSlice";

export default function configureStore(
  history: History,
  initialState?: ApplicationState
) {
  const middleware = [thunk, routerMiddleware(history)];

  const rootReducer = combineReducers({
    ...reducers,
    applicationSettings,
    monitorItems,
    users,
    monitors,
    scheduleUpdates,
    roles,
    stores,
      globalSearch,
    application,
    router: connectRouter(history),
  });

  const enhancers = [];
  const windowIfDefined =
    typeof window === "undefined" ? null : (window as any);
  if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
    enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
