import axios from "axios";
import { apiUrl } from "../../config/appConfig";
import { Store } from "../../models/Store";

const endpoint = `${apiUrl}/api/Store`;

export const getStores = () => {
  return new Promise((resolve, reject) => {
    axios.get(`${endpoint}`).then((response) => resolve(response.data));
  });
};

export const getStoreById = async (storeId: string) => {
  return await axios.get(`${endpoint}/${storeId}`);
};

export const saveStore = async (id: number, store: Store) => {
  return await axios.put(`${endpoint}/${id}`, store);
};

export const createStore = async (store: Store) => {
  return await axios.post(`${endpoint}`, store);
};

export const removeStore = async (storeId: number) => {
  return await axios.delete(`${endpoint}/${storeId}/delete`);
};

export const searchStores = async (searchText: string) => {
  return await axios.get(`${endpoint}/search/${searchText}`);
};
