import validate from "validate.js";

const menuItemValidator = (menuItem) =>
    validate(menuItem, {
        name: {
            presence: { allowEmpty: false, message: "^'Name' is required" },
        },
        price: {
            presence: { allowEmpty: false, message: "^'Price' is required" },
        },
        calories: {
            presence: { allowEmpty: false, message: "^'Calories' is required" },
        },
        menuItemTypeId: {
            presence: { allowEmpty: false, message: "^'Menu Item Type' is required" },
            numericality: {
                greaterThan: 0,
                message: "^'Menu Item Type' is required" 
            },
        },
    });

export { menuItemValidator };
