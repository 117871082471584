import React, { useEffect, useState } from "react";

import { Grid, makeStyles, useTheme } from "@material-ui/core";
import MenuItemCard from "./MenuItemCard";
import { MenuItem } from "../../models/MenuItem";
import {
  getMenuItems,
  searchMenu,
} from "../../services/MenuItems/menuItemService";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAppContext } from "../../context/AppContext";
import _ from "lodash";
import { green } from "@material-ui/core/colors";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  center: {
    width: "100px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const MenuItemLogMobile = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [menuItems, setMenuItems] = useState<Array<MenuItem>>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { searchText, setSearchText } = useAppContext();
  const { children, value, index, ...other } = props;

  useEffect(() => {
    setIsLoading(true);
    searchMenuByText();
    setIsLoading(false);

    return () => {};
  }, []);

  useEffect(() => {
    setIsLoading(true);
    searchMenuByText();
    setIsLoading(false);
  }, [searchText]);

  const searchMenuByText = _.debounce(async () => {
    try {
      const { data: searchedMenuitems } = await searchMenu(searchText);
      setMenuItems(searchedMenuitems);
    } catch (error) {
    } finally {
    }
  }, 500);

  const loadMenuItems = async () => {
    setIsLoading(true);
    try {
      const { data: menuItemsResult } = await getMenuItems();
      if (menuItemsResult) {
        setMenuItems(menuItemsResult);
      }
    } catch (error) {
      enqueueSnackbar("failed to load menu items group.", { variant: "error" });
      enqueueSnackbar(error, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const handleAdd = () => {
    history.push("/menuItems/add/0");
  };

  const fabs = [
    {
      color: "primary",
      className: classes.fab,
      icon: <AddIcon />,
      label: "Add",
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className={classes.center}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12}>
            Menu Items
          </Grid>
          {menuItems.map((menuItem: MenuItem) => {
            return (
              <Grid item xs={12}>
                <MenuItemCard menuItem={menuItem} />
              </Grid>
            );
          })}
        </Grid>
      )}
      <Zoom
        key="primary"
        in={value === index}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
        }}
        unmountOnExit
      >
        <Fab
          aria-label="Add"
          className={classes.fab}
          color="primary"
          onClick={handleAdd}
        >
          <AddIcon />
        </Fab>
      </Zoom>
    </>
  );
};

export default MenuItemLogMobile;
