import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { getToken } from "../../services/Login/logService";
import { LoginRequest } from "../../models/LoginRequest";
import { useHistory } from "react-router";
import { useAppContext } from "../../context/AppContext";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import * as Cookies from "js-cookie";
import geiImg from "../../images/logos/gei_logo_2.png";
import { useValidator } from "../../hooks/validatorHook";
import { passwordResetValidator, PASSWORD_CONSTRAINTS } from "../../validations/passwordResetValidator";
import { useParams } from "react-router";
import {
  resetPassword,
  emailPaswordReset,
} from "../../services/Users/userService";
import { PasswordReset } from "../../models/PasswordReset";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    maxWidth: "500px",
    maxHeight: "750px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  input: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "16px",
  },
  inputExtras: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
  },
}));

interface IPasswordReset {
  userId: string;
  token: string;
}

const Resetpassword = () => {
  const { userId, token } = useParams<IPasswordReset>();
  const history = useHistory();
  const classes = useStyles();

  const { setAuthorized, setToken, setGlobalUser, setAppRoles } =
    useAppContext();
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({
    password: "",
    confirmPassword: "",
  });

  const { validator, setShouldShowErrors } = useValidator(
    passwordResetValidator,
    login
  );

  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    resetUserPassword();
  };

  const resetUserPassword = async () => {
    if (!validateData()) return;
    try {
      var passwordResetObj = new PasswordReset();
      passwordResetObj.password = login.password;
      passwordResetObj.token = token;
      passwordResetObj.userId = parseInt(userId);
      var result = await resetPassword(passwordResetObj);
    } catch (error) {}
    if (result) {
      enqueueSnackbar("password reset successfully", {
        variant: "success",
      });
      history.push("/login");
    } else {
      enqueueSnackbar("failed to reset password", { variant: "error" });
    }
  };

  const onChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let updatedLogin = { ...login };
    updatedLogin[name] = value;
    setLogin(updatedLogin);
  };

  return (
    <div className={clsx(classes.root)}>
      <div>
        <Card>
          <CardContent className={classes.inputExtras}>
            <img
              src={geiImg}
              alt="gei_logo"
              width="409px"
              height="265px"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
            <Typography variant="h6" className={classes.inputExtras}>
              Reset your Password
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                name="password"
                className={classes.input}
                label="New Password"
                autoFocus
                type="password"
                error={
                  validator
                    ? validator.shouldShowErrors &&
                      !!validator.result["password"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["password"]
                    : null
                }
                variant="outlined"
                disabled={loading}
                required
                fullWidth
                defaultValue={login.password}
                value={login.password}
                onChange={onChange}
              />
              <TextField
                name="confirmPassword"
                className={classes.input}
                label="Confirm Password"
                type="password"
                error={
                  validator
                    ? validator.shouldShowErrors &&
                      !!validator.result["confirmPassword"]
                    : false
                }
                helperText={
                  validator
                    ? validator.shouldShowErrors && validator.result["confirmPassword"]
                    : null
                }
                variant="outlined"
                disabled={loading}
                required
                fullWidth
                value={login.confirmPassword}
                InputProps={{
                  className: "pr-2",
                  type: showPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
              />

              <div className={classes.inputExtras}>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="RESET"
                  //disabled={_.isEmpty(dirtyFields) || !isValid}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Reset Password"}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Resetpassword;
