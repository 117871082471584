import axios from "axios";
import { apiUrl } from "../../config/appConfig";
import { MenuItem } from "../../models/MenuItem";

const endpoint = `${apiUrl}/api/Menu`;

export const getMenuItems = async () => {
  return await axios.get(`${endpoint}`);
};

export const getMenuItemById = async (id: string) => {
  return await axios.get(`${endpoint}/${id}`);
  
};
export const saveMenuItem = async (id: number, menuItem: MenuItem) => {
  return await axios.put(`${endpoint}/${id}`, menuItem);
};

export const createMenuItem = async (menuItem: MenuItem) => {
    return await axios.post(`${endpoint}`, menuItem);
};

export const deleteMenuItem = async (id: number) => {
    return await axios.delete(`${endpoint}/${id}`);
};

export const searchMenu = async (searchText: string) =>{
    return await axios.get(`${endpoint}/search/${searchText}`)
}

