import {
  Paper,
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
  makeStyles,
  Fab,
  useTheme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Zoom from "@material-ui/core/Zoom";
import React, { useState, useEffect } from "react";
//import userSelector from "../../components/user/userSelector";
import { BackButton } from "../../components/Common/backButton";
import { useParams } from "react-router";
import { User } from "../../models/User";
import { useSnackbar } from "notistack";
import { useValidator } from "../../hooks/validatorHook";
import { userValidator } from "../../validations/userValidator";
import { formatter } from "../../helpers/formatter";
import {
  getUserById,
  saveUser,
  createUser,
} from "../../services/Users/userService";
import { useAppContext } from "../../context/AppContext";
import RoleSelector from "../../components/Role/RoleSelector";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface IRoleParams {
  mode: string;
  userId: string;
}
const UserEdit = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile, roleHelper } = useAppContext();
  const { children, value, index, ...other } = props;
  const [user, setUser] = useState<User>(new User());
  const { mode, userId } = useParams<IRoleParams>();
  const { enqueueSnackbar } = useSnackbar();

  const { validator, setShouldShowErrors } = useValidator(userValidator, user);

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const isSuperAdmin = roleHelper.isSuperAdmin();

  useEffect(() => {
    if (mode == "edit") {
      loaduser();
    }
    return () => {};
  }, []);

  const loaduser = async () => {
    try {
      const { data: menuResult } = await getUserById(userId);
      if (menuResult) {
        setUser(menuResult);
      }
    } catch (error) {
      enqueueSnackbar("failed to load menu items group.", { variant: "error" });
    }
  };
  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setUser({ ...user, [name]: value });
  };

  const onSave = async () => {
    if (!validateData()) return;
    let success = false;
    try {
      if (Number(userId) > 0) {
        const { data } = await saveUser(user.id, user);
        success = data;
      } else {
        const { data } = await createUser(user);
        success = data;
      }
      if (success) {
        enqueueSnackbar("successfully saved user item", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("failed to save user item", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("failed to save user item.", { variant: "error" });
    }
  };

  return (
    <Card>
      <CardHeader
        title={`${mode === "edit" ? "Edit" : "Add"} user Items`}
        action={
          <>
            <BackButton />
          </>
        }
      />
      <CardContent>
        <Divider />
        <br />
        <Grid container direction="row" spacing={4} md={6}>
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              label="user Name"
              variant="outlined"
              required
              disabled={!isSuperAdmin}
              defaultValue={user.username}
              value={user.username}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["username"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["username"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="firstName"
              label="First Name"
              variant="outlined"
              required
              disabled={!isSuperAdmin}
              defaultValue={user.firstName}
              value={user.firstName}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors &&
                    !!validator.result["firstName"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["firstName"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="lastName"
              label="Last Name"
              variant="outlined"
              required
              disabled={!isSuperAdmin}
              defaultValue={user.lastName}
              value={user.lastName}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["lastName"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["lastName"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <RoleSelector
              name="roleId"
              label="Role"
              variant="outlined"
              required
              disabled={!isSuperAdmin}
              defaultValue={user.roleId}
              value={user.roleId}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["roleId"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["roleId"]
                  : null
              }
            />
          </Grid>
        </Grid>
        <br />
        {!isMobile && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              disabled={!isSuperAdmin}
            >
              Save
            </Button>
          </Grid>
        )}
        {isMobile && (
          <>
            <Zoom
              key="primary"
              in={value === index}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  value === index ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Fab
                aria-label="Add"
                className={classes.fab}
                color="primary"
                onClick={onSave}
                disabled={!isSuperAdmin}
              >
                <SaveIcon />
              </Fab>
            </Zoom>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default UserEdit;
