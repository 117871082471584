import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationState } from "../../../store/pagination/paginationState";
import { SearchState } from "../../../store/search/searchState";
import {
  searchMonitors,
  searchMonitorsByStoreId,
} from "../../../services/Monitor/monitorService";
import {
    getMonitorItemsByMonitorId, saveMonitorItems
} from "../../../services/MonitorItem/monitorItemService";
import { Monitor } from "../../../models/Monitor";
import { MonitorItem } from "../../../models/MonitorItem";
import { getScheduledRequestByMonitorId, getRequestMonitorItems} from "../../../services/ScheduleUpdate/scheduleUpdateService"
interface ISearchProp {
  storeId: number;
  searchText: string;
}

const paginationState = new PaginationState(
  "program-management/user/log/pagination",
  {}
);

const searchState = new SearchState(
  "program-management/user/log/pagination",
  {}
);

export const getItemsAsync = createAsyncThunk(
  "monitors/getMonitors",
  async (search: ISearchProp, { rejectWithValue }) => {
    const { storeId, searchText } = search;
    return searchMonitors(searchText, storeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const getRequestsAsync = createAsyncThunk(
    "monitors/getRequests",
    async (monitorId: number, { rejectWithValue }) => {
        return getScheduledRequestByMonitorId(monitorId)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                rejectWithValue(error);
            });
    }
);

export const getStoreMonitorsAsync = createAsyncThunk(
  "monitors/getMonitorsForSelector",
  async (storeId: number, { rejectWithValue }) => {
    return searchMonitorsByStoreId(storeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const getMonitorItemsAsync = createAsyncThunk(
    "monitor/monitor-items",
    async (monitorId: number , { rejectWithValue }) => {
        return getMonitorItemsByMonitorId(monitorId)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return rejectWithValue(error);
            })
    }
)

export const getRequestMonitorItemsAsync = createAsyncThunk(
    "monitor/requested-monitor-items",
    async (requestId: number, { rejectWithValue }) => {
        return getRequestMonitorItems(requestId)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return rejectWithValue(error);
            })
    }
)

export const saveMonitorItemsAsync = createAsyncThunk(
    "monitor/save-monitors",
    async (monitorItems: Array<MonitorItem>, { getState, rejectWithValue }) => {
        return saveMonitorItems(monitorItems)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                return rejectWithValue(error);
            })
    }
)
interface MonitorState {
    items: Array<Monitor>
    selectedMonitor: Monitor,
    availableMonitors: Array<Monitor>
    monitorItems: Array<MonitorItem>
    selectedMonitorItem: MonitorItem,
    requests: Array<Request>,
    pagination: any,
    search: any,
    currentViewStatus: string,
}

const initialState: MonitorState = {
    items: [],
    selectedMonitor: new Monitor(),
    availableMonitors: [],
    monitorItems: [],
    selectedMonitorItem: new MonitorItem(),
    requests: [],
    pagination: paginationState.getInitialState,
    search: searchState.getInitialState,
    currentViewStatus: "",
}

const monitorsSlice = createSlice({
  name: "monitors",
  initialState,
    reducers: {
    setRequests: (state, action) => {
        state.requests = action.payload;
    },
    setSelectedMonitor: (state, action) => {
      state.selectedMonitor = action.payload;
    },
    setMonitorItems: (state, action) =>{
       state.monitorItems = action.payload;
    },
    setSelectedMonitorItem: (state, action) => {
        state.selectedMonitorItem = action.payload;
    },
    setCurrentViewStatus: (state, action) => {
        state.currentViewStatus = action.payload
    },
    ...paginationState.reducers,
    ...searchState.reducers,
  },
    extraReducers: (builder) => {
    builder.addCase(getMonitorItemsAsync.fulfilled, (state, actions) => {
      state.monitorItems = actions.payload;
    });
    builder.addCase(getItemsAsync.fulfilled, (state, actions) => {
      state.items = actions.payload;
    });
    builder.addCase(getStoreMonitorsAsync.fulfilled, (state, actions) => {
      state.availableMonitors = actions.payload;
    });
    builder.addCase(getRequestsAsync.fulfilled, (state, actions) => {
        state.requests = actions.payload || [];
    });
    builder.addCase(getRequestMonitorItemsAsync.fulfilled, (state, actions) => {
        state.monitorItems = actions.payload || [];
    });
  },
});

export const { setCurrentViewStatus, setPageSize, setPageNumber, setSelectedMonitor, setSearchText, setMonitorItems, setSelectedMonitorItem } =
  monitorsSlice.actions;

export const monitors = monitorsSlice.reducer;
