import axios from "axios";
//import Cookies from "universal-cookie";
import { apiUrl } from "../../config/appConfig";
import * as Cookies from "js-cookie";
import { User } from "../../models/User";
import { PasswordReset } from "../../models/PasswordReset";
import { EmailPasswordReset } from "../../models/EmailPasswordReset";

const endpoint = `${apiUrl}/api/Users`;

export const renewJWT = async () => {
  return axios.get("/api/Users/RenewJWT");
};

export const logoutUser = () => {
  Cookies.remove("token");
  Cookies.remove("userInfo");
};

export const getUserByUserName = async (userName: string) => {
  return await axios.get(`${endpoint}/username?userName=${userName}`);
};

export const getUsers = async () => {
  return await axios.get(`${endpoint}`);
};

export const getUserById = async (id: string) => {
  return await axios.get(`${endpoint}/${id}`);
};

export const saveUser = async (id: number, user: User) => {
  return await axios.put(`${endpoint}/${id}`, user);
};

export const createUser = async (user: User) => {
  return await axios.post(`${endpoint}`, user);
};

export const deleteUser = async (id: number) => {
  return await axios.delete(`${endpoint}/${id}`);
};

export const searchUsers = async (searchText: string, storeId: number) => {
  return await axios.get(`${endpoint}/store/${storeId}/search/${searchText}`);
};

export const resetPassword = async (passwordReset: PasswordReset) => {
  return await axios.post(`${endpoint}/ResetPassword`, passwordReset);
};

export const emailPaswordReset = async (
  emailPasswordReset: EmailPasswordReset
) => {
  return await axios.post(`${endpoint}/EmailPasswordReset`, emailPasswordReset);
};

export const checkIsChalkboardUser = async (userName: string) =>  {
    const result = await axios.get(`${endpoint}/ischalkboard/${userName}`);
    return result;
}