import axios from "axios";
import { apiUrl } from "../../config/appConfig";
import { Monitor } from "../../models/Monitor";

const endpoint = `${apiUrl}/api/Monitor`;

export const getMonitors = async () => {
  return await axios.get(`${endpoint}`);
};

export const getMonitorById = async (id: string) => {
  return await axios.get(`${endpoint}/${id}`);
};

export const getMonitorImageById = async (id: string) => {
  return await axios.get(`${endpoint}/monitorImageUrl/${id}`);
}

export const saveMonitor = async (id: number, menuItem: Monitor) => {
  return await axios.put(`${endpoint}/${id}`, menuItem);
};

export const createMonitor = async (menuItem: Monitor) => {
    return await axios.post(`${endpoint}`, menuItem);
};

export const deleteMonitor = async (id: number) => {
    return await axios.delete(`${endpoint}/${id}`);
};

export const searchMonitors = async (searchText: string, storeId: number) =>{
    return await axios.get(`${endpoint}/store/${storeId}/search/${searchText}`)
}

export const searchMonitorsByStoreId =  async (storeId: number) => {
  const data = await axios.get(`${endpoint}/store/${storeId}`)
  return data;
}