import { createSelector } from "reselect";

const Roles = (state) => state.roles;

export const itemSelector = createSelector(
    Roles,
    (Roles) => Roles.items
  );

export const paginationSelector = createSelector(
    Roles,
    (Roles) => Roles.pagination
);