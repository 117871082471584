import React, { useEffect, useState } from "react";
import { MenuItem, Select, InputLabel } from "@material-ui/core";
import { searchMonitorsByStoreId } from "../../services/Monitor/monitorService";
import { Monitor } from "../../models/Monitor";
import { shallowEqual, useSelector } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";

const MonitorSelector = (props: any) => {
  const [monitors, setMonitors] = useState<Array<Monitor>>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const { name, label, value, onChange, error, helperText } = props;
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);

  useEffect(() => {
    loadMonitors();
  }, []);

  const loadMonitors = async () => {
    setLoading(true);
    try {
      const { data: monitors } = await searchMonitorsByStoreId(
        selectedStore.id
      );
      setMonitors(monitors);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        fullWidth
        {...props}
        variant="outlined"
      >
        {monitors.map((type) => {
          return <MenuItem value={type.id}>{type.name}</MenuItem>;
        })}
      </Select>
      <div style={{ color: "#f44336", fontSize: "0.75rem" }}>{helperText}</div>
    </>
  );
};

export default MonitorSelector;
