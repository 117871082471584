import validate from "validate.js";

const monitorValidator = (menuItem) =>
    validate(menuItem, {
        name: {
            presence: { allowEmpty: false, message: "^'Name' is required" },
        },
    });

export { monitorValidator };
