import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import {
  ListItem,
  Tooltip,
  List,
  ListItemText,
  Icon,
  ListItemIcon,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  link: {
    cursor: "pointer",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function SimpleAccordion({ title, titleIcon, subItems }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button color="primary" onClick={handleClick}>
        <ListItemIcon>{titleIcon}</ListItemIcon>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subItems.map((item) => {
            return (
              <ListItem button className={clsx(classes.link, classes.nested)} onClick={item.onClick}>
                <Tooltip arrow title={item.tooltipText} placement="right">
                  <ListItemIcon>{item.icon}</ListItemIcon>
                </Tooltip>
                <ListItemText primary={item.text} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </>
    // <div className={classes.root}>
    //   <Accordion>
    //     <AccordionSummary
    //       expandIcon={<ExpandMoreIcon />}
    //       aria-controls="panel1a-content"
    //       id="panel1a-header"
    //     >
    //       <Typography className={classes.heading}>{title}</Typography>
    //     </AccordionSummary>
    //     <AccordionDetails>
    //       <List>
    //         {subItems.map((item) => {
    //           return (
    //             <ListItem className={classes.link} onClick={item.onClick}>
    //               <Tooltip arrow title={item.tooltipText} placement="right">
    //                 <ListItemIcon>
    //                   {item.icon}
    //                 </ListItemIcon>
    //               </Tooltip>
    //               <ListItemText primary={item.text} />
    //             </ListItem>
    //           );
    //         })}
    //       </List>
    //     </AccordionDetails>
    //   </Accordion>
    // </div>
  );
}
