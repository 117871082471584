import {
  Paper,
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import BaseTable from "../../components/Common/BaseTable";
import React, { useEffect, useState } from "react";
import { BackButton } from "../../components/Common/backButton";
import MenuItemTypeSelector from "../../components/MenuItemType/MenuItemTypeSelector";
import MonitorItemSelector from "../../components/MonitorItem/MonitorItemSelector";
import { useParams } from "react-router";
import {
  getMenuItemById,
  saveMenuItem,
  createMenuItem,
} from "../../services/MenuItems/menuItemService";
import { MenuItem } from "../../models/MenuItem";
import { useSnackbar } from "notistack";
import { useValidator } from "../../hooks/validatorHook";
import { menuItemValidator } from "../../validations/menuItemValidator";
import { formatter } from "../../helpers/formatter";
import MonitorItemMultiSelector from "../../components/MonitorItem/MonitorItemMultiSelector";
import { useMenuItem } from "./hooks/useMenuItem";

const monitorItemsColumns = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "value",
    numeric: false,
    disablePadding: true,
    label: "Value",
  },
];

interface IMenuItemParams {
  mode: string;
  menuItemId: string;
}

const MenuItemEdit = () => {
  //const [menuItem, setMenuItem] = useState<MenuItem>(new MenuItem());
  const { mode, menuItemId } = useParams<IMenuItemParams>();
  const { enqueueSnackbar } = useSnackbar();
  const { menuItem, setMenuItem, validator, setShouldShowErrors } =
    useMenuItem();
  // const { validator, setShouldShowErrors } = useValidator(
  //   menuItemValidator,
  //   menuItem
  // );

  useEffect(() => {
    if (mode == "edit") {
      loadMenuItem();
    }
    return () => {};
  }, []);

  const loadMenuItem = async () => {
    try {
      const { data: menuItemResult } = await getMenuItemById(menuItemId);
      if (menuItemResult) {
        setMenuItem({
          ...menuItemResult,
          price: menuItemResult.price.toFixed(2),
        });
      }
    } catch (error) {
      enqueueSnackbar("failed to load menu items group.", { variant: "error" });
      enqueueSnackbar(error, { variant: "error" });
    }
  };
  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setMenuItem({ ...menuItem, [name]: value });
  };

  const onMonitorItemsChange = (items) => {
    setMenuItem({ ...menuItem, monitorItems: items });
  };
  const onSave = async () => {
    console.log("Save");
    console.log(menuItem);
    if (!validateData()) return;
    let success = false;
    try {
      menuItem.price = parseFloat(menuItem.price.toString());
      if (Number(menuItemId) > 0) {
        const { data } = await saveMenuItem(menuItem.id, menuItem);
        success = data;
      } else {
        const { data } = await createMenuItem(menuItem);
        success = data;
      }
      if (success) {
        enqueueSnackbar("successfully saved menu item", { variant: "success" });
      } else {
        enqueueSnackbar("failed to save menu item", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("failed to save menu item.", { variant: "error" });
    }
  };

  return (
    <Card>
      <CardHeader
        title={`${mode === "edit" ? "Edit" : "Add"} MenuItem`}
        action={
          <>
            <BackButton />
          </>
        }
      />
      <CardContent>
        <Divider />
        <br />
        <Grid container direction="row" spacing={4} md={6}>
          {/* <Paper  style={{width: "100%%"}} elevation={3}> */}
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              required
              defaultValue={menuItem.name}
              value={menuItem.name}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["name"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["name"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              name="price"
              label="Price"
              variant="outlined"
              required
              value={menuItem.price}
              onChange={onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["price"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["price"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="calories"
              label="Calories"
              variant="outlined"
              required
              value={menuItem.calories}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["calories"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["calories"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="extra"
              label="Extra"
              variant="outlined"
              value={menuItem.extra}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              fullWidth
              name="description"
              label="Description"
              variant="outlined"
              value={menuItem.description}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MenuItemTypeSelector
              name="menuItemTypeId"
              label="Menu Item Group"
              required
              value={menuItem.menuItemTypeId}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors &&
                    !!validator.result["menuItemTypeId"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors &&
                    validator.result["menuItemTypeId"]
                  : null
              }
            />
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <MonitorItemSelector
              name="monitorItemId"
              label="Monitor Item"
              required
              value={menuItem.monitorItemId}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors &&
                    !!validator.result["monitorItemId"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors &&
                    validator.result["monitorItemId"]
                  : null
              }
            />
          </Grid> */}
          <Grid item xs={12} spacing={4}>
            <MonitorItemMultiSelector
              selectedItems={menuItem.monitorItems}
              onMonitorItemsChange={onMonitorItemsChange}
            />
          </Grid>
          {/* <Grid item xs={12} md={12}>
            <BaseTable
              data={menuItem.monitorItems}
              tableTitle="monitorItems"
              searchTableData={() => {}}
              cellHeads={monitorItemsColumns}
              deleteData={() => {}}
              handleAdd={() => {}}
              handleEdit={() => {}}
              noPagination
            />
          </Grid> */}
          {/* </Paper> */}
        </Grid>
        <br />
        <Grid item xs={12} md={6} lg={6}>
          <Button variant="contained" color="primary" onClick={onSave}>
            Save
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MenuItemEdit;
