import axios from 'axios';
import { apiUrl } from '../../config/appConfig';
import { Contact } from '../../models/Contact';

const endpoint = `${apiUrl}/api/Contacts`

export const getContacts = async () => {
    return await axios.get(`${endpoint}`);
}

export const getContact = (contactId: number) => {
    return axios.get(`${endpoint}/${contactId}`);
}

export const addContact = async (newContact: Contact) => {
    return await axios.post(`${endpoint}`, newContact);
}

export const updateContact = (contactId: number, updatedContact: Contact) => {
    return axios.put(`${endpoint}/${contactId}`, updatedContact);
}

export const saveContact = (contactId: number, updatedContact: Contact) => {
    return axios.post(`${endpoint}/save/${contactId}`, updatedContact);
}

export const deleteContact = (contactId: number) => {
    return axios.delete(`${endpoint}/${contactId}`);
}