import React, { useEffect } from "react";
import { deleteMonitor } from "../../services/Monitor/monitorService";
import { CustomTable } from "../Common/CustomTable";
import { useAppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import MonitorMobileLog from "./MonitorMobileLog";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";
import { searchSelector } from "./store/monitorSelector";
import { setSearchText } from "./store/monitorSlice";
import {
  itemSelector,
  paginationSelector,
} from "../Monitor/store/monitorSelector";
import {
  setPageSize,
  setPageNumber,
  getItemsAsync,
} from "./store/monitorSlice";
import _ from "lodash";
import StoreSelector from "../Store/StoreSelector";
const monitorColumns = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "storeName",
    numeric: false,
    disablePadding: true,
    label: "Store",
  },
];

const MonitorLog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, roleHelper } = useAppContext();
  const paginationState = useSelector(paginationSelector, shallowEqual);
  const monitorState = useSelector(itemSelector, shallowEqual);
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);
  const { searchText } = useSelector(searchSelector, shallowEqual);

  const monitors = monitorState.map((monitor) => {
    return { ...monitor, storeName: monitor.store.name };
  });

  useEffect(() => {
    dispatch(setSearchText(""));
  }, []);

  const search = {
    handleSearch: _.debounce((value) => {
      dispatch(setSearchText(value));
    }, 500),
  };

  const pagination = {
    paginationState,
    setPageNumber,
    setPageSize,
  };

  const handleAdd = () => {
    history.push("/monitors/add/0");
  };
  const handeEdit = (id: number) => {
    history.push(`/monitors/edit/${id}`);
  };

  useEffect(() => {
    const selectedStoreId = selectedStore ? selectedStore.id : 0;
    const search = searchText ? searchText : "";
    dispatch(getItemsAsync({ storeId: selectedStoreId, searchText: search }));
  }, [selectedStore, searchText]);

  return (
    <>
    <StoreSelector />
      {isMobile ? (
        <MonitorMobileLog rows={monitors} />
      ) : (
        <CustomTable
          tableTitle="monitors"
          rows={monitors}
          cellHeads={monitorColumns}
          handleAdd={handleAdd}
          handeEdit={handeEdit}
          deleteData={deleteMonitor}
          isSuperAdmin={roleHelper.isSuperAdmin()}
          pagination={pagination}
          search={search}
        />
      )}
    </>
  );
};

export default MonitorLog;
