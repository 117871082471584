import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationState } from "../../../store/pagination/paginationState";
import { searchMonitorItems } from "../../../services/MonitorItem/monitorItemService";
import { searchMonitors } from "../../../services/Monitor/monitorService";
import { SearchState } from "../../../store/search/searchState";

interface ISearchProp {
  storeId: number;
  selectedMonitorId: number;
  searchText: string;
}
const paginationState = new PaginationState(
  "program-management/user/log/pagination",
  {}
);

const searchState = new SearchState(
  "program-management/user/log/pagination",
  {}
);

export const getItemsAsync = createAsyncThunk(
  "monitor-items/getMonitorItems",
  async (search: ISearchProp, { dispatch, rejectWithValue }) => {
    const { storeId, selectedMonitorId, searchText } = search;
    return searchMonitorItems(searchText, selectedMonitorId, storeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const getMonitorsAsync = createAsyncThunk(
  "monitors/getMonitors",
  async (search: ISearchProp, { rejectWithValue }) => {
    const { storeId, searchText } = search;
    return searchMonitors(searchText, storeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

const monitorItemsSlice = createSlice({
  name: "monitor-items",
  initialState: {
    items: [],
    monitors: [],
    selectedMonitor: {},
    pagination: paginationState.getInitialState,
    search: searchState.getInitialState,
  },
  reducers: {
    setSelectedMonitor: (state, actions) => {},
    ...paginationState.reducers,
    ...searchState.reducers,
  },
  extraReducers: (builder) => {
    builder.addCase(getItemsAsync.fulfilled, (state, actions) => {
      state.items = actions.payload;
    });
    builder.addCase(getMonitorsAsync.fulfilled, (state, actions) => {
      state.monitors = actions.payload;
    });
  },
});

export const { setPageSize, setPageNumber, setSearchText } =
  monitorItemsSlice.actions;

export const monitorItems = monitorItemsSlice.reducer;
