import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router";
import {formatter} from "../../helpers/formatter";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const MonitorCard = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = props;

  const onEdit = () => {
    history.push(`/users/edit/${user.id}`);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {`${user.firstName} ${user.lastName}`}
        </Typography>
        <Typography variant="body2" component="p">
          <b>UserName: </b> {user.username}
        </Typography>
      </CardContent>
      <CardActions>
        <div>
          <Button onClick={onEdit} variant="contained" color="primary">
            <EditIcon />edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

export default MonitorCard;
