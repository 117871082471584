import React from "react";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import { useAppContext } from "../../context/AppContext";

const PrivateRoute = (props ) => {
    const { authorized } = useAppContext();
  return <>{authorized ? <Route {...props} /> : <Redirect to="/pages/login" />}</>;
};

export default PrivateRoute;
