import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useAppContext } from "../../context/AppContext";
import { useHistory } from "react-router";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Alert() {
  const history = useHistory();
  const { title, contentText, onConfirm, onDeny, showAlert, setShowAlert, logoff } =
    useAppContext();

  const handleDeny = () => {
    logoff();
    setShowAlert(false);
  };

  const handleAgree = () => {
    onConfirm();
    setShowAlert(false);
    history.push("/login");
  };

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <div>
      <Dialog
        open={showAlert}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeny} color="primary">
            No
          </Button>
          <Button onClick={handleAgree} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
