import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { getToken } from "../../services/Login/logService";
import { LoginRequest } from "../../models/LoginRequest";
import { useHistory } from "react-router";
import { useAppContext } from "../../context/AppContext";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import * as Cookies from "js-cookie";
import {
  getUserByUserName,
  checkIsChalkboardUser,
} from "../../services/Users/userService";
import { getRoles } from "../../services/Roles/roleService";
import Logo from "../../images/logos/gei_logo_2.png";
import DarkLogo from "../../images/logos/gei_logo_3.png";
import { PAGES } from "../../shared/urlTemplates";
import { useDispatch } from "react-redux";
import { getAppSettingsAsync } from "../../store/applicationSettingsSlice";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
    maxWidth: "500px",
    maxHeight: "750px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  input: {
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "16px",
  },
  inputExtras: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
  },
  inputLinks: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "fit-content",
  },
}));

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const {
    setAuthorized,
    setToken,
    setGlobalUser,
    setAppRoles,
    darkMode,
    setIsChalkboardUser,
  } = useAppContext();
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({
    rememberMe: false,
    userName: "",
    password: "",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    loginAction();
  };

  useEffect(() => {
    const userName = Cookies.get("userName");
    const rememberMe = Cookies.get("rememberMe");
    if (rememberMe == "true" && userName) {
      let updatedLogin = { ...login };
      updatedLogin.userName = userName;
      updatedLogin.rememberMe = true;
      setLogin(updatedLogin);
    }
  }, []);

  const loginAction = async () => {
    setLoading(true);
    console.log("login");
    let result;
    try {
      const { data: isChalkboardUser } = await checkIsChalkboardUser(
        login.userName
      );
      setIsChalkboardUser(isChalkboardUser);
      result = await getToken({
        userName: login.userName,
        password: login.password,
        isChalkboardUser: Boolean(isChalkboardUser),
      });
      if (result) {
        setToken(result.data.token);
        if (login.rememberMe) {
          Cookies.set("rememberMe", "true");
          Cookies.set("userName", login.userName);
        } else {
          Cookies.set("rememberMe", false);
          Cookies.set("userName", "");
        }
        const userResult = await getUserByUserName(login.userName);
        const roleResult = await getRoles();

        Cookies.set("userRole", userResult.data.roleName);
        Cookies.set("userRoles", JSON.stringify(roleResult.data));
        dispatch(getAppSettingsAsync());
        setAppRoles(roleResult.data);
        setGlobalUser(userResult.data);
        setAuthorized(true);
        isChalkboardUser
          ? history.push(`/chalkboardmonitor/${userResult.data.parentStoreId}`)
          : history.push("/");
      } else {
        enqueueSnackbar("incorrect username and/or password", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("invalid username and/or password", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  const onChange = (event) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    let updatedLogin = { ...login };
    updatedLogin[name] = value;
    setLogin(updatedLogin);
  };

  const handleRegister = () => {
    history.push("/register");
  };

  return (
    <div className={clsx(classes.root)}>
      <div>
        <Card>
          <CardContent className={classes.inputExtras}>
            <img
              src={darkMode ? DarkLogo : Logo}
              alt="gei_logo"
              width={darkMode ? "400px" : "409px"}
              height={darkMode ? "117px" : "265px"}
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
            <Typography variant="h6" className={classes.inputExtras}>
              Login to your account
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                name="userName"
                className={classes.input}
                label="UserName"
                autoFocus
                //error={!!errors.email}
                //helperText={errors?.email?.message}
                variant="outlined"
                disabled={loading}
                required
                fullWidth
                defaultValue={login.userName}
                value={login.userName}
                onChange={onChange}
              />
              <TextField
                name="password"
                className={classes.input}
                label="Password"
                type="password"
                //error={!!errors.password}
                //helperText={errors?.password?.message}
                variant="outlined"
                disabled={loading}
                required
                fullWidth
                value={login.password}
                InputProps={{
                  className: "pr-2",
                  type: showPassword ? "text" : "password",
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={onChange}
              />
              <div className="font-normal">
                <FormControl>
                  <FormControlLabel
                    label="Remember Me"
                    name="rememberMe"
                    control={
                      <Checkbox
                        name="rememberMe"
                        checked={login.rememberMe}
                        value={login.rememberMe}
                        color="primary"
                        onChange={onChange}
                      />
                    }
                  />
                </FormControl>
              </div>

              <div className={classes.inputExtras}>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="LOG IN"
                  //disabled={_.isEmpty(dirtyFields) || !isValid}
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <CircularProgress /> : "Login"}
                </Button>
              </div>
              <div className="font-normal">&nbsp;</div>
              <div className={classes.inputExtras}>
                <Link className="font-normal" to={PAGES.FORGOTPASSWORD}>
                  Forgot Password?
                </Link>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Login;
