import React, { useEffect } from "react";
import componentRoutes from "../../routes/componentRoutes";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import { SideBar } from "../Sidebar/sideBar";
import { Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
const Dashboard = () => {
  return (
    <SideBar buildDate={"0.0.0.0"}>
      <Switch>
        {componentRoutes.map((prop, key) => {
          return (
            <PrivateRoute
              key={key}
              exact={prop.exact}
              path={prop.path}
              component={prop.component}
            />
          );
        })}
      </Switch>
    </SideBar>
  );
};

export default Dashboard;
