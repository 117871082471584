export class ScheduleUpdate{
    public id: number = 0;
    public name: string = "";
    public friendlyName: string = "";
    public storeId: number=0;
    public monitorId: number=0;
    public menuItemId: number=0;
    public monitorItemId: number=0;
    public description: string = "";
    public value: string = "";
    public active: boolean = true;
    public class: string = "";
    public width: number = 0;
    public height: number = 0;
    public top: number = 0;
    public left: number = 0;
    public isDeleted: boolean = false;
    public scheduledDate: Date = new Date();
    public updateStatus: number = 1;
}