import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { logoutUser } from "../../services/Users/userService";
import { Button, Drawer, Grid, Paper, Tooltip, Link } from "@material-ui/core";
import { makeStyles, useTheme, fade } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import { AccountCircle } from "@material-ui/icons";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import MenuIcon from "@material-ui/icons/Menu";
import EventIcon from "@material-ui/icons/Event";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import AddGroup from "../GroupTable/addGroup";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import FeedbackIcon from "@material-ui/icons/Feedback";
import { useHistory } from "react-router";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Contacts from "../Contacts/Contacts";
import Settings from "../Settings/Settings";
import Footer from "../Footer/Footer";
import componentRoutes from "../../routes/componentRoutes";
import { PAGES } from "../../shared/urlTemplates";
import Popover from "@material-ui/core/Popover";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness5Icon from "@material-ui/icons/Brightness5";
import StoreIcon from "@material-ui/icons/Store";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import SimpleAccordion from "../../components/Common/SimpleAccordion";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import AppsIcon from "@material-ui/icons/Apps";
import PersonalVideoIcon from "@material-ui/icons/PersonalVideo";
import { useAppContext } from "../../context/AppContext";
import { useSelector, shallowEqual, dispatch, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";
import { searchSelector } from "../../store/global-search/globalSearchSelector";
import {
  createMuiTheme,
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core/styles";
import StoreSelector from "../Store/StoreSelector";
import { setGlobalSearch } from "../../store/global-search/globalSearchSlice";
import { gei_logo_2 } from "../../images/logos/gei_logo_2.png";
const drawerWidth = 240;
const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#F9A800",
    borderColor: "#F9A800",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  bottomPush: {
    position: "relative",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
  },
  buttonColor: {
    backgroundColor: "#F9A800",
  },
  account: {
    color: "white",
  },
  typography: {
    padding: theme.spacing(2),
  },
  link: {
    cursor: "pointer",
  },
}));

export const SideBar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isMobile, darkMode, setDarkMode, authorized, setAuthorized } =
    useAppContext();
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileAnchorEl, setMobileAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(true);
  const [mobilePopoverOpen, setmobilePopoverOpen] = useState(false);
  const searchText = useSelector(searchSelector, shallowEqual);
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);

  useEffect(() => {
    if (isMobile) {
      handleDrawerClose();
    } else {
      handleCloseMobile();
      handleDrawerOpen();
    }
  }, [isMobile]);

  const handleDrawerOpen = () => {
    if (!isMobile) {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleContactsClick = () => {
    history.push("/table/contacts");
  };
  const handleSettingsClick = () => {
    history.push("/settings");
  };
  const handleAddContactClick = () => {
    history.push("/contacts/add");
  };
  const handleGroupsClick = () => {
    history.push("/table/groups");
  };
  const handleMenuItemsClick = () => {
    if (isMobile) handleCloseMobile();
    history.push("/menuitems");
  };
  const handleUsersClick = () => {
    if (isMobile) handleCloseMobile();
    history.push("/users");
  };

  const handleUserRolesClick = () => {
    if (isMobile) handleCloseMobile();
    history.push("/roles");
  };

  const handleDocumentViewerClick = () => {
    history.push("/documentviewer");
  };
  const handleFeedbackClick = () => {
    history.push("/feedback");
  };
  const handleDistributionClick = () => {
    history.push("/distribution");
  };
  const handledDashboardClick = () => {
    if (isMobile) handleCloseMobile();
    history.push("/dashboard");
  };

  const handleMonitorItemClick = () => {
    if (isMobile) handleCloseMobile();
    history.push("/monitorItems");
  };

  const handleStoreClick = () => {
    if (isMobile) handleCloseMobile();
    history.push(PAGES.STORES);
  };
  const handleScheduleUpdateClick = () => {
    if (isMobile) handleCloseMobile();
    history.push("/scheduleupdates");
  };

  const handleMonitorClick = () => {
    if (isMobile) handleCloseMobile();
    history.push("/monitors");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const mobileMenuClick = (event) => {
    setMobileAnchorEl(event.currentTarget);
  };

  const handleCloseMobile = () => {
    setmobilePopoverOpen(false);
    setMobileAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    setAuthorized(false);
    logoutUser();
    handleClose();
    history.push("/pages/login");
  };
  const toggleDarkMode = () => {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  };

  const handleSearch = (event) => {
    dispatch(setGlobalSearch(event.target.value));
  };
  const userManagement = [
    {
      text: "Users",
      onClick: handleUsersClick,
      icon: <PeopleIcon />,
      tooltipText: "Users",
    },
    {
      text: "User Roles",
      onClick: handleUserRolesClick,
      icon: <WorkOutlineIcon />,
      tooltipText: "User Roles",
    },
  ];
  const popOverOpen = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const mobilePopOverOpen = Boolean(mobileAnchorEl);
  const mobilePopoverId = mobilePopoverOpen ? "mobile-menu-popover" : undefined;

  if (!authorized) {
    return <>{props.children}</>;
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: false,
        })}
      >
        <Toolbar>
          <IconButton
            aria-describedby={mobilePopoverId}
            color="inherit"
            aria-label="open drawer"
            onClick={isMobile ? mobileMenuClick : handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Popover
            id={mobilePopoverId}
            open={mobilePopOverOpen}
            anchorEl={mobileAnchorEl}
            onClose={handleCloseMobile}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <ListItem className={classes.link} onClick={handledDashboardClick}>
              {/* <Tooltip arrow title="Dashboard" placement="right">
                <ListItemIcon>
                  <DashboardIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip> */}

              <ListItemText primary="Dashboard" />
            </ListItem>
            {/* <ListItem className={classes.link} onClick={handleMenuItemsClick}>
              <Tooltip arrow title="MenuItems" placement="right">
                <ListItemIcon>
                  <RestaurantMenuIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Menu Items" />
            </ListItem> */}
            <ListItem className={classes.link} onClick={handleMonitorClick}>
              <Tooltip arrow title="Monitors" placement="right">
                <ListItemIcon>
                  <PersonalVideoIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Monitors" />
            </ListItem>
            <ListItem className={classes.link} onClick={handleMonitorItemClick}>
              <Tooltip arrow title="MonitorItems" placement="right">
                <ListItemIcon>
                  <AppsIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Monitor Items" />
            </ListItem>
            <ListItem className={classes.link} onClick={handleStoreClick}>
              <Tooltip arrow title="Stores" placement="right">
                <ListItemIcon>
                  <StoreIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Stores" />
            </ListItem>
            <ListItem
              className={classes.link}
              onClick={handleScheduleUpdateClick}
            >
              <Tooltip arrow title="ScheduleUpdates" placement="right">
                <ListItemIcon>
                  <EventIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Schedule Updates" />
            </ListItem>
            {userManagement.map((item) => {
              return (
                <ListItem className={classes.link} onClick={item.onClick}>
                  <Tooltip arrow title={item.tooltipText} placement="right">
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={item.text} />
                </ListItem>
              );
            })}
          </Popover>
          <Box display="flex" flexGrow={1}>
            <Typography className={classes.title} variant="h6" noWrap>
              <img
                src={require("../../images/logos/gei_logo_4.png")}
                height="32px"
                width="32px"
                alt="logo"
              />
              &nbsp; {`DMB ${selectedStore ? selectedStore.name : ""}`}
            </Typography>
          </Box>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearch}
              value={searchText}
            />
          </div>
          <IconButton onClick={toggleDarkMode}>
            <Tooltip title="Toggle light/dark theme">
              {darkMode ? (
                <Brightness5Icon />
              ) : (
                <Brightness4Icon style={{ color: "white" }} />
              )}
            </Tooltip>
          </IconButton>
          <IconButton aria-describedby={id} onClick={handleClick}>
            <AccountCircle className={classes.account} />
          </IconButton>
          <Popover
            id={id}
            open={popOverOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List>
              <ListItem>
                <Typography className={classes.typography}>
                  User Profile
                </Typography>
              </ListItem>
              <ListItem onClick={handleLogOut}>
                <Typography className={classes.typography}>Log Out</Typography>
              </ListItem>
            </List>
          </Popover>
        </Toolbar>
      </AppBar>
      {!isMobile && (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: false,
            [classes.drawerClose]: true,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: false,
              [classes.drawerClose]: true,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <List>
            {/* <ListItem>{open && <StoreSelector />}</ListItem> */}
            {/* <ListItem onClick={handleDistributionClick}>
              {open && (
                <Tooltip arrow title="Distribution" placement="right">
                  <BootstrapButton variant="contained" color="#F9A800">
                    <SendIcon className={classes.icons} />
                    {"Distribution "}
                  </BootstrapButton>
                </Tooltip>
              )}
              {!open && (
                <Tooltip arrow title="Distribution" placement="right">
                  <ListItemIcon>
                    <SendIcon className={classes.icons} />
                  </ListItemIcon>
                </Tooltip>
              )}
            </ListItem> */}
            {/* <Divider /> */}
            {/* <ListItem
              button
              className={classes.link}
              onClick={handledDashboardClick}
            >
              <Tooltip arrow title="Dashboard" placement="right">
                <ListItemIcon>
                  <DashboardIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>

              <ListItemText primary="Dashboard" />
            </ListItem> */}
            {/* <ListItem button className={classes.link} onClick={handleMenuItemsClick}>
              <Tooltip arrow title="MenuItems" placement="right">
                <ListItemIcon>
                  <RestaurantMenuIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Menu Items" />
            </ListItem> */}
            <ListItem className={classes.link} onClick={handleMonitorClick}>
              <Tooltip arrow title="Monitors" placement="right">
                <ListItemIcon>
                  <PersonalVideoIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Monitors" />
            </ListItem>
            <ListItem
              button
              className={classes.link}
              onClick={handleMonitorItemClick}
            >
              <Tooltip arrow title="MonitorItems" placement="right">
                <ListItemIcon>
                  <AppsIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Monitor Items" />
            </ListItem>
            <ListItem className={classes.link} onClick={handleStoreClick}>
              <Tooltip arrow title="Stores" placement="right">
                <ListItemIcon>
                  <StoreIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Stores" />
            </ListItem>
            <ListItem
              className={classes.link}
              onClick={handleScheduleUpdateClick}
            >
              <Tooltip arrow title="ScheduleUpdates" placement="right">
                <ListItemIcon>
                  <EventIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Schedule Updates" />
            </ListItem>
            <SimpleAccordion
              title={"Manage"}
              titleIcon={<PeopleOutlineIcon />}
              subItems={userManagement}
            />
            {/* <ListItem className={classes.link} onClick={handleUserRolesClick}>
              <Tooltip arrow title="UserRoles" placement="right">
                <ListItemIcon>
                  <PeopleIcon className={classes.icons} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="User Roles" />
            </ListItem> */}
            {/* <ListItem onClick={handleGroupsClick}>
                        <Tooltip arrow title="Groups" placement="right">
                            <ListItemIcon ><PeopleIcon className={classes.icons} /></ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Groups" />
                    </ListItem>
                    <ListItem onClick={handleContactsClick}>
                        <Tooltip arrow title="Contacts" placement="right">
                            <ListItemIcon ><PermContactCalendarIcon className={classes.icons} /></ListItemIcon>
                        </Tooltip>
                        <ListItemText primary="Contacts" />
                    </ListItem> */}
          </List>
          <div className={classes.bottomPush}>
            <Divider />
            <List>
              {/* <ListItem onClick={handleSettingsClick}>
                <Tooltip arrow title="Settings" placement="right">
                  <ListItemIcon>
                    <SettingsIcon className={classes.icons} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Settings" />
              </ListItem> */}
              <ListItem button onClick={handleFeedbackClick}>
                <Tooltip arrow title="Feedback" placement="right">
                  <ListItemIcon>
                    <FeedbackIcon className={classes.icons} />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Feedback" />
              </ListItem>
            </List>
          </div>
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <>{props.children}</>
        <Footer buildDate={props.buildDate} />
      </main>
    </div>
  );
};
