import React, { FormEvent, useState } from 'react';
import { Button, Card, CardContent, CardHeader, Grid, TextField, Typography } from '@material-ui/core';
import { BackButton } from '../Common/backButton';
import { useHistory } from 'react-router';
import { Group } from '../../models/Group';
import { addGroup } from '../../services/Groups/groupService';
import { useSnackbar } from 'notistack';

const AddGroup = () => {
    const [group, setGroup] = useState<Group>(new Group);
    let history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const handleSave = async() => {
        try {
            group.clientId = 1;
            group.createdby = "System";
            let { data: success } = await addGroup(group);
            if (success) {
                enqueueSnackbar('successfully added.', { variant: "success" });
                history.goBack();
            }
            else {
                enqueueSnackbar('failed to add group.', { variant: "error" });
            }
        }
        catch (error) {
            console.log(error);
            enqueueSnackbar('failed to add group.', { variant: "error" });
        }
        
    }

    const handleChange = (event: any) => {
        let value = event.target.value;
        let key = event.target.id;
        group[key] = value;
        setGroup({ ...group });
    }

    return (
        <>
            <Card>
                <CardHeader
                    title={`Add Group`}
                    action={
                        <BackButton />
                    } />
                <CardContent>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="name"
                                label="Group Name"
                                onChange={handleChange}
                                value={group.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="description"
                                label="Group Description"
                                onChange={handleChange}
                                value={group.description}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
        )
}

export default AddGroup;