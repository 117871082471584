import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Monitor } from "../../models/Monitor";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  monitorSelector,
  availableMonitorSelector,
} from "./store/monitorSelector";
import {
  setSelectedMonitor,
  getStoreMonitorsAsync,
} from "./store/monitorSlice";
import { selectedStoreSelector } from "../Store/store/storesSelector";

const GlobalMonitorSelector = () => {
  const dispatch = useDispatch();
  const globalSelectedMonitor = useSelector(monitorSelector, shallowEqual);
  const availableMonitors = useSelector(availableMonitorSelector, shallowEqual);
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);

  useEffect(() => {
    const selectedStoreId = selectedStore ? selectedStore.id : 0;
    dispatch(getStoreMonitorsAsync(selectedStoreId));
    // loadStores();
  }, []);

  const loadStores = async () => {
    dispatch(setSelectedMonitor({ id: 0, name: "ALL" }));
  };

    const handleChange = async (event: any, values: any) => {
    const data = await dispatch(setSelectedMonitor(values));
  };

  return (
    <Autocomplete
      id="combo-box-demo"
      options={availableMonitors}
      getOptionLabel={(option) => option.name}
      style={{ width: 300, color: "white", marginTop: "16px" }}
      size="small"
      renderInput={(params) => (
        <TextField {...params} label="Selected Monitor" variant="outlined" />
      )}
      defaultValue={availableMonitors[0]}
      value={globalSelectedMonitor}
      onChange={handleChange}
    />
  );
};

export default GlobalMonitorSelector;
