import BaseTable from "../components/Common/CustomTable";
import AddGroup from "../components/GroupTable/addGroup";
import GroupDetails from "../components/GroupTable/groupDetails";
import Contacts from "../components/Contacts/Contacts";
import Settings from "../components/Settings/Settings";
import AddContact from "../components/Contacts/addContact";
import DocumentViewer from "../components/DocumentViewer/DocumentViewer";
import Distribution from "../components/Distribution/Distribution";
import Feedback from "../components/Feedback/Feedback";
import Dashboard from "../components/Dashboard/Dashboard";
import ContactDetails from "../components/Contacts/ContactDetails";
import Register from "../components/Register/Register";
import { MenuItem } from "@material-ui/core";
import MenuItemLog from "../components/MenuItem/MenuItemLog";
import MenuItemEdit from "../components/MenuItem/MenuItemEdit";
import RolesLog from "../components/Role/Roles";
import RolesEdit from "../components/Role/RolesEdit";
import MonitorItemsLog from "../components/MonitorItem/MonitorItemsLog";
import MonitorItemEdit from "../components/MonitorItem/MonitorItemEdit";
import MonitorLog from "../components/Monitor/MonitorLog";
import MonitorEdit from "../components/Monitor/MonitorEdit";
import UserLog from "../components/User/UserLog";
import UserEdit from "../components/User/UserEdit";
import ScheduleUpdateLog from "../components/ScheduledUpdate/ScheduleUpdateLog";
import ScheduleUpdateEdit from "../components/ScheduledUpdate/ScheduleUpdateEdit";
import StoreLog from "../components/Store/StoreLog";
import StoreEdit from "../components/Store/StoreEdit";
import ChalkboardEdit from "../components/Monitor/ChalkboardEdit";
import MonitorViewer from "../components/MonitorViewer/MonitorViewer";
import { PAGES, EDIT_PAGES } from "../shared/urlTemplates";
var componentRoutes = [
  {
    path: "/register",
    component: Register,
    name: "Register",
    exact: true,
  },
  {
    path: "/",
    component: MonitorLog,
    name: "Monitors",
    exact: true,
  },
  {
    path: "/table/:tableObject",
    component: BaseTable,
    name: "BaseTable",
    exact: true,
  },
  {
    path: "/groups/add",
    component: AddGroup,
    name: "AddGroup",
    exact: true,
  },
  {
    path: "/groups/:mode/:groupId",
    component: GroupDetails,
    name: "GroupDetails",
    exact: true,
  },
  {
    path: "/settings",
    component: Settings,
    name: "Settings",
    exact: true,
  },
  {
    path: "/feedback",
    component: Feedback,
    name: "Feedback",
    exact: true,
  },
  {
    path: "/contacts/:mode/:contactId",
    component: AddContact,
    name: "AddContact",
    exact: true,
  },
  {
    path: "/documentviewer",
    component: DocumentViewer,
    name: "DocumentViewer",
    exact: true,
  },
  {
    path: "/distribution",
    component: Distribution,
    name: "Distribution",
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    exact: true,
  },
  {
    path: "/menuitems",
    component: MenuItemLog,
    name: "MenuItems",
    exact: true,
  },
  {
    path: "/menuitems/:mode/:menuItemId",
    component: MenuItemEdit,
    name: "MenuItemEdit",
    exact: true,
  },
  { path: "/roles", component: RolesLog, name: "Roles", exact: true },
  {
    path: "/roles/:mode/:roleId",
    component: RolesEdit,
    name: "RoleEdit",
    exact: true,
  },
  {
    path: "/monitorItems",
    component: MonitorItemsLog,
    name: "MonitorItem",
    exact: true,
  },
  {
    path: "/monitorItems/:mode/:monitorItemId",
    component: MonitorItemEdit,
    name: "MonitorItem",
    exact: true,
  },
  {
    path: "/monitors",
    component: MonitorLog,
    name: "Monitor",
    exact: true,
    },
    {
        path: "/chalkboardmonitor/:storeId",
        component: ChalkboardEdit,
        name: "Chalkboard Edit",
        exact: true,
    },
    {
        path: "/monitorview",
        component: MonitorViewer,
        name: "Monitor Viewer",
        exact: true,
    },
  {
    path: "/monitors/:mode/:monitorId",
    component: MonitorEdit,
    name: "Monitor",
    exact: true,
  },
  { path: "/users", component: UserLog, name: "User", exact: true },
  {
    path: "/users/:mode/:userId",
    component: UserEdit,
    name: "User",
    exact: true,
  },
  {
    path: "/scheduleupdates",
    component: ScheduleUpdateLog,
    name: "ScheduleUpdate",
    exact: true,
  },
  {
    path: "/ScheduleUpdates/:mode/:scheduleUpdateId",
    component: ScheduleUpdateEdit,
    name: "ScheduleUpdateEdit",
    exact: true,
  },
  { path: PAGES.STORES, component: StoreLog, name: "Stores", exact: true },
  {
    path: EDIT_PAGES.STORES,
    component: StoreEdit,
    name: "Store",
    exact: true,
  },
  { path: "/", component: MonitorLog, name: "Monitors", exact: true },
];

export default componentRoutes;
