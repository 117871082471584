import React, { useState, useEffect, useCallback } from "react";
import { CardContent, Card, Paper, Typography, Grid, TextField, Button, CardHeader } from "@material-ui/core"
import MonitorDisplay from "../MonitorDisplay/MonitorDisplay";
import { searchMonitorItems } from "../../services/MonitorItem/monitorItemService";
import "./MonitorViewer.css";
import _ from "lodash";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const MonitorViewer = () => {
    const [monitorItems, setMonitorItems] = useState([]);
    const [windowPostion, setWindowPosition] = useState({
        yPos: window.scrollY, xPos: window.scrollX
    })
    const [itemSelected, setItemSelected] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const originalWidth = 1920;
    const originalHeight = 1080;
    const viewWidth = 900;
    const viewHeight = (viewWidth / 16) * 9;
    const viewDimensions = {
        width: `${viewWidth}px`,
        height: `${viewHeight}px`
    }

    const handleScroll = useCallback(
        () => {
            setIsFetching(prevState => !prevState);
            console.log({ isFetching });
        },
        [isFetching]
    );

    useEffect(() => {
        loadMonitorItems();
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isFetching]);

    const loadMonitorItems = async () => {
        const { data: items } = await searchMonitorItems("", 1, 1);
        setMonitorItems(items);
    }

    const calculateTop = (top) => {
        const element = document.getElementById("monitor-container");
        const elTop = element.offsetTop - window.scrollY;
        const calculatedTop = elTop + (top * (element.offsetHeight / originalHeight));
        return calculatedTop;
    }

    const calculateLeft = (left) => {
        const element = document.getElementById("monitor-container");
        const elTop = element.offsetLeft - window.scrollX;
        const calculatedTop = elTop + (left * (element.offsetWidth / originalWidth));
        return calculatedTop;
    }
    const calculateFontSize = (itemClass) => {
        const size = (itemClass === "extra" ? 20 : 30) * (viewWidth / originalWidth);
        return `${size}px`;
    }

    const calculateHeight = (itemHeight) => {
        return itemHeight * (viewHeight / originalHeight)
    }

    const calculateWidth = (itemWidth) => {
        return itemWidth * (viewWidth / originalWidth)
    }

    const handleItemClick = (item) => {
        setItemSelected(item);
    }
    const handleReset = () => {
        loadMonitorItems();
    }
    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        let updatedMonitorItems = [...monitorItems];
        const updatedItemSelected = { ...itemSelected, value: value };
        const itemIndex = monitorItems.findIndex(x => x.id === itemSelected.id);
        updatedMonitorItems.splice(itemIndex, 1, updatedItemSelected);
        setItemSelected({ ...itemSelected, value: value });
        setMonitorItems(updatedMonitorItems);
    }
    return (
        <Card>
            <CardHeader
                title={`Monitor Edit`}
                    action={
                        <>
                            <Button color="primary" variant="contained">Save</Button>
                            <Button color="default" variant="contained" onClick={handleReset}>Reset</Button>
                        </> }
                />
            <CardContent>
                <div className="flex">
                    <div id="monitor-container"
                        style={{
                            backgroundImage: "url('https://dmbstore.blob.core.windows.net/dmb/images/1/backgroundImage.png?1629662314005')",
                            backgroundSize: "cover",
                            width: viewDimensions.width, height: viewDimensions.height, border: '1px solid rgba(0, 0, 0, 0.05)',
                        }}>
                        {monitorItems.map((monitorItem) => {
                            const isSelected = monitorItem.id === itemSelected?.id;
                        const { width, height, top, left } = monitorItem;
                        const itemStyle: CSSProperties = {
                            position: 'fixed',
                            width: calculateWidth(width),
                            height: calculateHeight(height),
                            top: calculateTop(top),
                            left: calculateLeft(left),
                            fontSize: calculateFontSize(monitorItem.class)
                        }
                        return (
                            <div onClick={() => handleItemClick(monitorItem)} className={`${monitorItem.class} ${isSelected ? "selected" : ""}`} style={itemStyle}>{monitorItem.value}</div>
                            )
                    })}
                    </div>
                    <div>
                        <Paper>
                            <Grid container direction="row" md={12} >
                                <Grid item md={12}>
                                    <TextField fullWidth multiline variant="outlined" label={itemSelected?.friendlyName || ""} value={itemSelected?.value || ""} onChange={handleChange} />
                                </Grid>
                            </Grid>
                            </Paper>
                    </div>
                </div>
            </CardContent>
        </Card>
        )
}

export default MonitorViewer;