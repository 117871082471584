import React, { useState } from "react";
import { IconButton, Typography, Icon, makeStyles } from "@material-ui/core";
import { CloudDownloadTwoTone, CloudUploadTwoTone } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import PublishIcon from "@material-ui/icons/Publish";
import { useEffect } from "react";
import UploadFileService from '../../../services/FileUpload/fileUploadService';
import "./fileUploader.css"
const useStyles = makeStyles({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }

    
})

const FileUploader = ({ name, onChange, value, componentKey, onUpload }) => {
    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(value);
    const [selectedFileName, setSelectedFileName] = useState<string>();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event.target.files[0]);
        setSelectedFileName(event.target.files[0].name);
        setIsFilePicked(true);
        onChange(event.target.files[0]);
    };
    const handleDelete = () => {
        setSelectedFile(null);
        setIsFilePicked(false);
        onChange(null);
    };
    const handleUpload = () => {
        UploadFileService.uploadBackgroundImageFile(componentKey, selectedFile, () => {});
        //onUpload(selectedFile);
        setUploaded(true);
    };

    const formatFileName = (value: string) => {
        var fileName = value.replace(/^.*[\\\/]/, "");
        return fileName;
    };

    useEffect(() => {
        setSelectedFileName(formatFileName(value));
    }, []);

    return (
        <div className="flex items-center">
            <div>
                <label htmlFor={`icon-button-file-${componentKey}`}>
                    <input
                        style={{ display: "none" }}
                        id={`icon-button-file-${componentKey}`}
                        name={`icon-button-file-upl${componentKey}oader`}
                        type="file"
                        onChange={changeHandler}
                    />

                    <IconButton
                        component="span"
                        aria-label="upload picture"
                        className="text-green-400"
                    >
                        <CloudUploadTwoTone />
                    </IconButton>
                </label>
            </div>
            {!value && <div>Attach</div>}

            {value && (
                <>
                    <div className="flex items-center">
                        <div>
                            <a href={`https://dmbstore.blob.core.windows.net/dmb/images/${componentKey}/${selectedFileName}`}>{selectedFileName}</a>
                        </div>
                        <div>
                            <IconButton onClick={handleDelete}>
                                <DeleteIcon className="text-red-400" />
                            </IconButton>
                        </div>
                    </div>
                    <div>
                        <IconButton className="hover:bg-transparent" onClick={handleUpload}>
                            <PublishIcon/>
                            <Typography
                                variant="button"
                                color="secondary"
                                className="text-12 pl-8 whitespace-no-wrap"
                            >
                                Upload
                            </Typography>
                        </IconButton>
                    </div>
                </>
            )}
        </div>
    );
};

export default FileUploader;
