import axios from "axios";
import { apiUrl } from '../../config/appConfig';
const endpoint = `${apiUrl}/api/fileUpload`

class UploadFileService {
    uploadBackgroundImageFile(monitorId, file, onUploadProgress) {
      let formData = new FormData();
  
      formData.append("file", file);
  
      return axios.post(`${endpoint}/monitor/${monitorId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
    }
  
    getFiles() {
      return axios.get("/files");
    }
  }
  
  export default new UploadFileService();