import React, { useEffect } from "react";
import { deleteMonitorItem } from "../../services/MonitorItem/monitorItemService";
import { CustomTable } from "../Common/CustomTable";
import { useAppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import MonitorItemMobile from "./MonitorItemMobileLog";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";
import { monitorSelector } from "../Monitor/store/monitorSelector";
import {
  itemSelector,
  paginationSelector,
} from "../MonitorItem/store/monitorItemSelector";
import {
  setPageSize,
  setPageNumber,
  getItemsAsync,
} from "../MonitorItem/store/monitorItemSlice";
import { searchSelector } from "./store/monitorItemSelector";
import { setSearchText } from "./store/monitorItemSlice";
import GlobalMonitorSelector from "../Monitor/GlobalMonitorSelector";
import _ from "lodash";
import StoreSelector from "../Store/StoreSelector";

const monitorItemsColumns = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  { id: "value", numeric: false, disablePadding: true, label: "Value" },
  {
    id: "storeName",
    numeric: false,
    disablePadding: true,
    label: "Store Name",
  },
];

const MonitorItemsLog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paginationState = useSelector(paginationSelector, shallowEqual);
  const monitorItemState = useSelector(itemSelector, shallowEqual);
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);
  const { searchText } = useSelector(searchSelector, shallowEqual);
  const selectedMonitor = useSelector(monitorSelector, shallowEqual);

  const monitorItems = monitorItemState.map((monitorItem) => {
    return { ...monitorItem, storeName: monitorItem.store.name };
  });

  useEffect(() => {
    dispatch(setSearchText(""));
  }, []);

  const search = {
    handleSearch: _.debounce((value) => {
      dispatch(setSearchText(value));
    }, 500),
  };

  const {
    isMobile,
    roleHelper,
    contextPage,
    setContextPage,
    contextRowPerPage,
    setContextRowPerPage,
  } = useAppContext();

  const pagination = {
    paginationState,
    setPageSize,
    setPageNumber,
  };
  const handleAdd = () => {
    history.push("/monitorItems/add/0");
  };
  const handeEdit = (id: number) => {
    history.push(`/monitorItems/edit/${id}`);
  };

  useEffect(() => {
    const selectedStoreId = selectedStore ? selectedStore.id : 0;
    const search = searchText ? searchText : "";
    const selectedMonitorId = selectedMonitor ? selectedMonitor.id : 0;
    dispatch(
      getItemsAsync({
        storeId: selectedStoreId,
        selectedMonitorId: selectedMonitorId,
        searchText: search,
      })
    );
  }, [selectedStore, searchText, selectedMonitor]);

  return (
    <>
      <StoreSelector />
      <GlobalMonitorSelector />
      {isMobile ? (
              <MonitorItemMobile rows={monitorItems} search={search}/>
      ) : (
        <CustomTable
          tableTitle="MonitorItems"
          rows={monitorItems}
          cellHeads={monitorItemsColumns}
          handleAdd={handleAdd}
          handeEdit={handeEdit}
          deleteData={deleteMonitorItem}
          isSuperAdmin={roleHelper.isSuperAdmin()}
          contextPage={contextPage}
          setContextPage={setContextPage}
          contextRowPerPage={contextRowPerPage}
          setContextRowPerPage={setContextRowPerPage}
          pagination={pagination}
          search={search}
        />
      )}
    </>
  );
};

export default MonitorItemsLog;
