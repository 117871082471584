import { createSelector } from "reselect";

const MonitorItems = (state) => state.monitorItems;

export const itemSelector = createSelector(
  MonitorItems,
  (MonitorItems) => MonitorItems.items
);

export const paginationSelector = createSelector(
  MonitorItems,
  (MonitorItems) => MonitorItems.pagination
);

export const monitorSelector = createSelector(
  MonitorItems,
  (MonitorItems) => MonitorItems.monitors
);

export const sselectedMonitorSelector = createSelector(
  MonitorItems,
  (MonitorItems) => MonitorItems.selectedMonitor
);

export const searchSelector = createSelector(
  MonitorItems,
  (MonitorItems) => MonitorItems.search
);
