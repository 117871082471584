import React, { useState } from "react";
import { Grid, makeStyles, useTheme, Typography } from "@material-ui/core";
import ScheduleUpdateCard from "./ScheduleUpdateCard";
import { MenuItem } from "../../models/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: "relative",
    minHeight: 200,
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  center: {
    width: "100px",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const MenuItemLogMobile = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const { rows, children, value, index, ...other } = props;

  return (
    <>
      {isLoading ? (
        <div className={classes.center}>
          <CircularProgress size={100} />
        </div>
      ) : (
        <Grid container xs={12} spacing={3}>
          <Grid item xs={12}>
          <Typography variant="h6">Schedule Updates</Typography>
          </Grid>
          {rows.map((scheduleUpdate: MenuItem) => {
            return (
              <Grid item xs={12}>
                <ScheduleUpdateCard scheduleUpdate={scheduleUpdate} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
};

export default MenuItemLogMobile;
