import { createSelector } from "reselect";

const ScheduleUpdates = (state) => state.scheduleUpdates;

export const itemSelector = createSelector(
    ScheduleUpdates,
    (ScheduleUpdates) => ScheduleUpdates.items
  );

export const paginationSelector = createSelector(
    ScheduleUpdates,
    (ScheduleUpdates) => ScheduleUpdates.pagination
);