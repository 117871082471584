import React, { useState, useEffect, useCallback, useRef } from "react";
import { CardContent, Card, Paper, Typography, Grid, TextField, Button, CardHeader } from "@material-ui/core"
import MonitorDisplay from "../MonitorDisplay/MonitorDisplay";
import { searchMonitorItems } from "../../services/MonitorItem/monitorItemService";
import "./MonitorViewer.css";
import _ from "lodash";
import { MonitorItem } from "../../models/MonitorItem";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getMonitorItemsAsync, setMonitorItems, setSelectedMonitorItem, setCurrentViewStatus } from "../Monitor/store/monitorSlice";
import { monitorItemsSelector, selectedMonitorItemSelector } from "../Monitor/store/monitorSelector";
import { container } from "../../styles/material-dashboard-pro-react";
import { CSSProperties } from "react";

interface IMonitorViewProps {
    monitorId: number
    storeId: number
    width?: number
    height?: number
}
const MonitorViewComponent = (props: IMonitorViewProps) => {
    const { monitorId, storeId, width, height } = props;
    const containerElement = useRef();
    const dispatch = useDispatch();
    const monitorItems = useSelector(monitorItemsSelector, shallowEqual);
    const itemSelected = useSelector(selectedMonitorItemSelector, shallowEqual)
    //const [monitorItems, setMonitorItems] = useState<Array<MonitorItem>>([]);
    const [windowPostion, setWindowPosition] = useState({
        yPos: window.scrollY, xPos: window.scrollX
    })
    //const [itemSelected, setItemSelected] = useState<MonitorItem>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const originalWidth = width;
    const originalHeight = height;
    const viewWidth = width > height ? 900 : 400;
    const viewHeight = (viewWidth / originalWidth) * originalHeight;
    const viewDimensions = {
        width: `${viewWidth}px`,
        height: `${viewHeight}px`
    }

    const handleScroll = useCallback(
        () => {
            setIsFetching(prevState => !prevState);
            console.log({ isFetching });
        },
        [isFetching]
    );

    useEffect(() => {
        dispatch(getMonitorItemsAsync(monitorId));
        dispatch(setCurrentViewStatus("Current View"));
        //loadMonitorItems();
    }, [])

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [isFetching]);

    //const loadMonitorItems = async () => {
    //    const { data: items } = await searchMonitorItems("", monitorId, storeId);
    //    setMonitorItems(items);
    //}

    const calculateTop = (top) => {
        //const element = document.getElementById("monitor-container");
        if (containerElement.current) {
            const element = containerElement.current as HTMLElement | undefined;
            const elTop = element.offsetTop - window.scrollY;
            const calculatedTop = elTop + (top * (element.offsetHeight / originalHeight));
            return calculatedTop;
        }
        
    }

    const calculateLeft = (left) => {
        //const element = document.getElementById("monitor-container");
        if (containerElement.current) {
            const element = containerElement.current as HTMLElement | undefined;
            const elTop = element.offsetLeft - window.scrollX;
            const calculatedTop = elTop + (left * (element.offsetWidth / originalWidth));
            return calculatedTop;
        }
        
    }
    const calculateFontSize = (itemClass) => {
        let size = 30;
        switch (itemClass) {
            case "extra":
                size = 20
                break;
            case "itemsmall":
                size = 30;
                break;
            case "extra2":
                size = 20;
                break;
            case "feeds":
                size = 25;
            case "calsmall":
                size = 20;
            case "side":
                size = 25;
                break;
            case "cal":
                size = 30;
                break;
            case "item":
                size = 30;
                break
            case "chalkboad-item":
                size = 100;
                break;
            default:
                size = 30;
                break;
        }
        const elSize = size * (viewWidth / originalWidth);
        return `${elSize}px`;
    }

    const calculateHeight = (itemHeight) => {
        return itemHeight * (viewHeight / originalHeight)
    }

    const calculateWidth = (itemWidth) => {
        return itemWidth * (viewWidth / originalWidth)
    }

    const handleItemClick = (item) => {
        //setItemSelected(item);
        dispatch(setSelectedMonitorItem(item));
    }

    const handleReset = () => {
        dispatch(getMonitorItemsAsync(monitorId));
    }

    const handleChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;
        let updatedMonitorItems = [...monitorItems];
        const updatedItemSelected = { ...itemSelected, value: value };
        const itemIndex = monitorItems.findIndex(x => x.id === itemSelected.id);
        updatedMonitorItems.splice(itemIndex, 1, updatedItemSelected);
        dispatch(setSelectedMonitorItem({ ...itemSelected, value: value }));
        dispatch(setMonitorItems(updatedMonitorItems));
    }

    return (
        <div id="monitor-container" ref={containerElement}
                    style={{
                        backgroundImage: `url('https://dmbstore.blob.core.windows.net/dmb/images/${monitorId}/backgroundImage.png?1629662314005')`,
                        backgroundSize: "cover",
                        width: viewDimensions.width, height: viewDimensions.height, border: '1px solid rgba(0, 0, 0, 0.05)',
                    }}>
                    {monitorItems.map((monitorItem) => {
                        const isSelected = monitorItem.id === itemSelected?.id;
                        const { width, height, top, left } = monitorItem;
                        const itemStyle: CSSProperties = {
                            position: 'fixed',
                            width: calculateWidth(width),
                            height: calculateHeight(height),
                            top: calculateTop(top),
                            left: calculateLeft(left),
                            fontSize: calculateFontSize(monitorItem.class)
                        }
                        if (monitorItem?.active) {
                            return (
                                <div onClick={() => handleItemClick(monitorItem)} className={`${monitorItem.class} ${isSelected ? "selected" : ""}`} style={itemStyle}>{monitorItem.value}</div>
                            )
                        }
                    })}
            </div>
      )
}

export default MonitorViewComponent;