import {
    Grid,
    TextField,
    Card,
    CardContent,
    CardHeader,
    Button,
    Divider,
    Typography,
    FormControlLabel,
    Switch,
    makeStyles,
    Fab,
    useTheme,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";

import { useParams } from "react-router";

import { getChalkboardMonitorByStoreId } from "../../services/MonitorItem/monitorItemService";
const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}));

const ChalkboardEdit = (props) => {
    const { storeId } = useParams();
    const [monitorItems, setMonitorItems] = useState([]);

    useEffect(() => {
        loadMonitorItems();
    },[])

    const loadMonitorItems = async () => {
        const { data: monitorItems } = await getChalkboardMonitorByStoreId(storeId);
        setMonitorItems(monitorItems);
    }

    const onSave = () => {

    }

    return (
        <>
            <Card>
                <CardHeader
                    title={`Edit Chalkboard Fields`}
                />
                <CardContent>
                    <Divider />
                    <br />
                    <Grid container direction="row" spacing={4} md={6}>
                        {monitorItems && monitorItems.map((item, key) => {
                            return (<Grid item xs={12} md={6}>
                                <TextField
                                    name={item.id}
                                    label={item.friendlyName}
                                    variant="outlined"
                                    required
                                    value={item.value || ""}
                                />
                            </Grid>
                            )
                        })}
                        <Grid item xs={12} md={6} lg={6}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={onSave}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default ChalkboardEdit;
