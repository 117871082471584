import React, { useEffect } from "react";
import { removeStore } from "../../services/Stores/storeService";
import { CustomTable } from "../Common/CustomTable";
import { useAppContext } from "../../context/AppContext";
import { useHistory } from "react-router";
import StoreMobileLog from "./StoreMobileLog";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "./store/storesSelector";
import { searchSelector } from "./store/storesSelector";
import { setSearchText } from "./store/storesSlice";
import _ from "lodash";
import { logItemsSelector, paginationSelector } from "./store/storesSelector";
import {
  setPageSize,
  setPageNumber,
  searchItemsAsync,
} from "./store/storesSlice";

const storeColumns = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Store Id",
  },
  {
    id: "number",
    numeric: true,
    disablePadding: true,
    label: "Number",
  },
  { id: "name", numeric: false, disablePadding: true, label: "Store Name" },
];

const StoreLog = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isMobile, roleHelper } = useAppContext();
  const paginationState = useSelector(paginationSelector, shallowEqual);
  const stores = useSelector(logItemsSelector, shallowEqual);
  const selectedStore = useSelector(selectedStoreSelector, shallowEqual);
  const { searchText } = useSelector(searchSelector, shallowEqual);

  useEffect(() => {
    dispatch(setSearchText(""));
  }, []);

  const search = {
    handleSearch: _.debounce((value) => {
      dispatch(setSearchText(value));
    }, 500),
  };

  const pagination = {
    paginationState,
    setPageNumber,
    setPageSize,
  };

  const handleAdd = () => {
    history.push("/stores/add/0");
  };
  const handeEdit = (id: number) => {
    history.push(`/stores/edit/${id}`);
  };

  useEffect(() => {
    dispatch(searchItemsAsync());
  }, [selectedStore, searchText]);

  return (
    <>
      {isMobile ? (
        <StoreMobileLog rows={stores} />
      ) : (
        <CustomTable
          tableTitle="stores"
          rows={stores}
          cellHeads={storeColumns}
          handleAdd={handleAdd}
          handeEdit={handeEdit}
          deleteData={removeStore}
          isSuperAdmin={roleHelper.isSuperAdmin()}
          pagination={pagination}
          search={search}
        />
      )}
    </>
  );
};

export default StoreLog;
