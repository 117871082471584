import { CardContent, CardHeader, Card, Button } from '@material-ui/core'
import DocumentViewer from '../DocumentViewer/DocumentViewer';
import React from 'react'
import { BackButton } from '../Common/backButton'

const Feedback = () => {

    return (
        <Card>
            <CardHeader
                title={"Feedback"}
                action={
                    <BackButton />
                }
            />
            <CardContent>
                <Button
                    variant="contained" color="primary"
                >
                    Save
                </Button>
                <DocumentViewer />
            </CardContent>
        </Card>
        )
}

export default Feedback;