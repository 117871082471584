import validate from "validate.js";

const roleValidator = (menuItem) =>
    validate(menuItem, {
        roleName: {
            presence: { allowEmpty: false, message: "^'Role Name' is required" },
        },
        description: {
            presence: { allowEmpty: false, message: "^'Description' is required" },
        },
    });

export { roleValidator };
