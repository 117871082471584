import validate from "validate.js";

const storeValidator = (store) =>
  validate(store, {
    name: {
      presence: { allowEmpty: false, message: "^'Name' is required" },
    },
  });

export { storeValidator };
