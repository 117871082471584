import {
  Grid,
  TextField,
  Card,
  CardContent,
  CardHeader,
  Button,
  Divider,
  InputAdornment,
  Typography,
  FormControlLabel,
  Switch,
  makeStyles,
  Fab,
  useTheme,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import Zoom from "@material-ui/core/Zoom";
import React, { useState, useEffect } from "react";
// import StoreSelector from "./StoreSelector";
import { BackButton } from "../../components/Common/backButton";
import { useParams } from "react-router";
import {
  saveRole,
  createRole,
  getRoleById,
} from "../../services/Roles/roleService";
import { Store } from "../../models/Store";
import { useSnackbar } from "notistack";
import { useValidator } from "../../hooks/validatorHook";
import { storeValidator } from "../../validations/storeValidator";
import { formatter } from "../../helpers/formatter";
import {
  getStoreById,
  saveStore,
  createStore,
} from "../../services/Stores/storeService";
import { useAppContext } from "../../context/AppContext";
import FileUploader from "../../components/Common/FileUpload/FileUploader";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { selectedStoreSelector } from "../Store/store/storesSelector";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface IRoleParams {
  mode: string;
  storeId: string;
}

const StoreEdit = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { isMobile, roleHelper } = useAppContext();
  const { children, value, index, ...other } = props;
  const [store, setstore] = useState<Store>(new Store());
  const { mode, storeId } = useParams<IRoleParams>();
  const { enqueueSnackbar } = useSnackbar();

  const { validator, setShouldShowErrors } = useValidator(
    storeValidator,
    store
  );

  const isSuperAdmin = roleHelper.isSuperAdmin();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  useEffect(() => {
    if (mode == "edit") {
      loadStore();
    }
    return () => {};
  }, []);

  const loadStore = async () => {
    try {
      const { data: menuResult } = await getStoreById(storeId);
      if (menuResult) {
        setstore(menuResult);
      }
    } catch (error) {
      enqueueSnackbar("failed to load menu items group.", { variant: "error" });
    }
  };
  const validateData = () => {
    const isValid = false;
    if (validator) {
      const _isValid = validator.isValid();
      setShouldShowErrors(!_isValid);
      return _isValid;
    }
    return isValid;
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setstore({ ...store, [name]: value });
  };

  const onSave = async () => {
    if (!validateData()) return;
    let success = false;
    try {
      if (Number(storeId) > 0) {
        const { data } = await saveStore(store.id, store);
        success = data;
      } else {
        const { data } = await createStore(store);
        success = data;
      }
      if (success) {
        enqueueSnackbar("successfully saved store item", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("failed to save store item", { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("failed to save store item.", { variant: "error" });
    }
  };

  return (
    <Card>
      <CardHeader
        title={`${mode === "edit" ? "Edit" : "Add"} Store Items`}
        action={
          <>
            <BackButton />
          </>
        }
      />
      <CardContent>
        <Divider />
        <br />
        <Grid container direction="row" spacing={4} md={6}>
          <Grid item xs={12} md={6}>
            <TextField
              name="id"
              label="Store Id"
              variant="outlined"
              required
              disabled
              defaultValue={store.id}
              value={store.id}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["id"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["id"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="name"
              label="Name"
              variant="outlined"
              required
              disabled={!isSuperAdmin}
              defaultValue={store.name}
              value={store.name}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["name"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["name"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="number"
              label="Number"
              variant="outlined"
              disabled={!isSuperAdmin}
              defaultValue={store.number}
              value={store.number}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["number"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["number"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="address"
              label="Address"
              variant="outlined"
              disabled={!isSuperAdmin}
              defaultValue={store.address}
              value={store.address}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["address"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["address"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="suite"
              label="Suite"
              variant="outlined"
              disabled={!isSuperAdmin}
              defaultValue={store.suite}
              value={store.suite}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["suite"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["suite"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="city"
              label="City"
              variant="outlined"
              disabled={!isSuperAdmin}
              defaultValue={store.city}
              value={store.city}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["city"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["city"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="zip"
              label="Zip"
              variant="outlined"
              disabled={!isSuperAdmin}
              defaultValue={store.zip}
              value={store.zip}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["zip"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["zip"]
                  : null
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="state"
              label="State"
              variant="outlined"
              disabled={!isSuperAdmin}
              defaultValue={store.state}
              value={store.state}
              onChange={onChange}
              error={
                validator
                  ? validator.shouldShowErrors && !!validator.result["state"]
                  : false
              }
              helperText={
                validator
                  ? validator.shouldShowErrors && validator.result["state"]
                  : null
              }
            />
          </Grid>
        </Grid>
        <br />
        {!isMobile && (
          <Grid item xs={12} md={6} lg={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              disabled={!isSuperAdmin}
            >
              Save
            </Button>
          </Grid>
        )}
        {isMobile && (
          <>
            <Zoom
              key="primary"
              in={value === index}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  value === index ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Fab
                aria-label="Add"
                className={classes.fab}
                color="primary"
                disabled={!isSuperAdmin}
                onClick={onSave}
              >
                <SaveIcon />
              </Fab>
            </Zoom>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default StoreEdit;
