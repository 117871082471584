import { TurnedIn } from "@material-ui/icons";
import validate from "validate.js";

const passwordResetValidator = (passwordReset, passwordConstraints) =>
    validate(passwordReset, {
        password: {
            presence: true,
            length:{
                maximum: 50,
                message: "^'Password' must be less than 50 characters"
            },
            format:{
                pattern: /(?=[A-Za-z0-9!@#$%^&*]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,50}).*$/,
                message: "^'Password' needs min 8 characters. Must have 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character (!@#$%^&*)",
            }
        },
        confirmPassword: {
            equality: {
              attribute: "password",
              message: "^Passwords don't match",
            },
        }
    });

export { passwordResetValidator };


export const PASSWORD_CONSTRAINTS = {
    low: {
      presence: true,
      passwordValidation: {
        pattern: /^.{6,50}$/,
        flags: "i",
        message: "^'Password' needs min 6 letters or numbers.",
      },
    },
    medium: {
      presence: true,
      passwordValidation: {
        pattern: /^(?=.*\d)((?=.*[a-zA-Z])|(?=.*[W])).{6,50}/,
        flags: "i",
        message: "^'Password' needs min 6 characters with at least 1 number.",
      },
    },
    high: {
      presence: true,
      passwordValidation: {
        pattern: /(?=[A-Za-z0-9!@#$%^&*]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,50}).*$/,
        flags: "i",
        message:
          "^'Password' needs min 8 characters. Must have 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character (!@#$%^&*)",
      },
    },
  };
  