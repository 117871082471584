import validate from "validate.js";

const monitorItemValidator = (menuItem) =>
    validate(menuItem, {
        name: {
            presence: { allowEmpty: false, message: "^'Name' is required" },
        },
        width: {
            presence: { allowEmpty: false, message: "^'Width' is required" },
        },
        height: {
            presence: { allowEmpty: false, message: "^'Height' is required" },
        },
        top: {
            presence: { allowEmpty: false, message: "^'Top' is required" },
        },
        left: {
            presence: { allowEmpty: false, message: "^'Left' is required" },
        },
    });

export { monitorItemValidator };
