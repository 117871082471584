export class MonitorItem {
  public id: number = 0;
  public name: string = "";
  public friendlyName: string = "";
  public description: string = "";
  public value: string = "";
  public active: boolean = true;
  public class: string = "";
  public width: number = 0;
  public height: number = 0;
  public top: number = 0;
  public left: number = 0;
  public monitorId: number = 0;
  public storeName: string = "";
  public storeId: number = 0;
}
