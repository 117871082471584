import axios from "axios";
import { apiUrl } from "../../config/appConfig";
import { ScheduleUpdate } from "../../models/ScheduleUpdate";
import { ScheduleMonitorUpdate } from "../../models/ScheduleMonitorUpdate";

const endpoint = `${apiUrl}/api/ScheduledUpdate`;

export const getScheduleUpdates = async () => {
return await axios.get(`${endpoint}`);
};

export const getScheduleUpdateById = async (ScheduleUpdateId: string) => {
  return await axios.get(`${endpoint}/${ScheduleUpdateId}`);
};

export const saveScheduleUpdate = async (id: number, ScheduleUpdate: ScheduleUpdate) => {
  return await axios.put(`${endpoint}/${id}`, ScheduleUpdate);
};

export const createScheduleUpdate = async (ScheduleUpdate: ScheduleUpdate) => {
  const data = await axios.post(`${endpoint}`, ScheduleUpdate);
  return data;
};

export const removeScheduleUpdate = async (ScheduleUpdateId: number) => {
  return await axios.delete(`${endpoint}/${ScheduleUpdateId}/delete`);
};

export const searchScheduleUpdates = async (searchText: string, storeId: number) => {
  return await axios.get(`${endpoint}/store/${storeId}/search/${searchText}`);
};
//ScheduleMonitorUpdate
export const requestSchduleUpdates = async (scheduleMonitorUpdate: ScheduleMonitorUpdate) => {
    return await axios.post(`${endpoint}/monitorupdate`, scheduleMonitorUpdate);
}

export const getScheduledRequestByMonitorId = async (monitorId: number) => {
    return await axios.get(`${endpoint}/scheduledupdates/${monitorId}`)
}

export const getRequestMonitorItems = async (requestId: number) => {
    return await axios.get(`${endpoint}/request/${requestId}`);
}

export const cancelScheduleRequest = async (requestId: number) => {
    return await axios.post(`${endpoint}/cancel-request/${requestId}`);
}