import React, { useState } from "react";
import { MenuItem } from "../../../models/MenuItem";
import { MonitorItem } from "../../../models/MonitorItem";
import { useSnackbar } from "notistack";
import { useValidator } from "../../../hooks/validatorHook";
import { menuItemValidator } from "../../../validations/menuItemValidator";

interface IUseMenuItem {
  selectedItems?: Array<MonitorItem>;
}

export const useMenuItem = () => {
  const [menuItem, setMenuItem] = useState<MenuItem>(new MenuItem());
  const [availableMonitorItems, setAvailableMonitorItems] = useState<
    Array<MonitorItem>
  >([]);
  const [monitorItems, setMonitorItems] = useState<Array<MonitorItem>>([]);
  const [selectedItemValues, setSelectedItems] = useState<Array<MonitorItem>>([]
  );
  const { validator, setShouldShowErrors } = useValidator(
    menuItemValidator,
    menuItem
  );

  return {
    menuItem,
    setMenuItem,
    validator,
    setShouldShowErrors,
    availableMonitorItems,
    setAvailableMonitorItems,
    monitorItems,
    setMonitorItems,
    selectedItemValues,
    setSelectedItems,
  };
};
