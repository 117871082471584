// ##############################
// // // Footer styles
// #############################


const containerFluid = {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
        display: "table",
        content: '" "'
    },
    "&:after": {
        clear: "both"
    }
};

const container = {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    "@media (min-width: 768px)": {
        width: "750px"
    },
    "@media (min-width: 992px)": {
        width: "970px"
    },
    "@media (min-width: 1200px)": {
        width: "1170px"
    },
    "&:before,&:after": {
        display: "table",
        content: '" "'
    },
    "&:after": {
        clear: "both"
    }
};

const defaultFont = {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "300",
    lineHeight: "1.5em"
};

const primaryColor = "#1C3E95";

const footerStyle = {
    block: {},
    left: {
        float: "left!important",
        display: "block"
    },
    right: {
        margin: "0",
        fontSize: "14px",
        float: "right!important",
        padding: "15px"
    },
    center: {
        margin: "0",
        fontSize: "14px",
        textAlign: "center",
        padding: "15px"
    },
    footer: {
        bottom: "0",
        borderTop: "1px solid #e7e7e7",
        padding: "15px 0",
        ...defaultFont,
        zIndex: 4
    },
    container: {
        zIndex: 3,
        ...container,
        position: "relative"
    },
    containerFluid: {
        zIndex: 3,
        ...containerFluid,
        position: "relative"
    },
    a: {
        color: primaryColor,
        textDecoration: "none",
        backgroundColor: "transparent"
    },
    list: {
        marginBottom: "0",
        padding: "0",
        marginTop: "0"
    },
    inlineBlock: {
        display: "inline-block",
        padding: "0",
        width: "auto"
    },
    whiteColor: {
        "&,&:hover,&:focus": {
            color: "#FFFFFF"
        }
    }
};
export default footerStyle;
