import React, { useState } from 'react'
import { Grid, Paper, TextField } from "@material-ui/core";
import { RegisterUser } from "../../models/RegisterUser";


const Register = () => {
    const [newUser, setNewUser] = useState<RegisterUser>(new RegisterUser());
    return (
        <Paper>
            <Grid container>
                <Grid item>
                    <TextField
                        label="First Name"
                        name="firstName"
                        value
                    />
                </Grid>
            </Grid>
        </Paper>
        )
}

export default Register;