import React from "react";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  makeStyles,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router";


const MonitorCard = (props: any) => {
  const history = useHistory();
  const { monitor } = props;

  const onEdit = () => {
    history.push(`/monitors/edit/${monitor.id}`);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {monitor.name}
        </Typography>
        <Typography variant="body2" component="p">
          <b>Description: </b> {monitor.description}
        </Typography>
        <Typography variant="body2" component="p">
          <b>StoreId: </b> {monitor.storeId}
        </Typography>
      </CardContent>
      <CardActions>
        <div>
          <Button onClick={onEdit} variant="contained" color="primary">
            <EditIcon />edit
          </Button>
        </div>
      </CardActions>
    </Card>
  );
};

export default MonitorCard;
