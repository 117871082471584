import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationState } from "../../../store/pagination/paginationState";
import { getStores, searchStores } from "../../../services/Stores/storeService";
import { SearchState } from "../../../store/search/searchState";

const paginationState = new PaginationState(
  "program-management/user/log/pagination"
);

const searchState = new SearchState(
  "program-management/user/log/pagination",
  {}
);

export const getItemsAsync = createAsyncThunk(
  "stores/getstores",
    async (val, { dispatch, rejectWithValue, getState }) => {
        let {stores }= getState();
    return getStores()
        .then((response) => {
            if (stores?.selectedStore?.id === 0) {
                dispatch(setSelectedStore(response[0]));
            }
        return response;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

export const searchItemsAsync = createAsyncThunk(
  "stores/searchStores",
  async (val, { dispatch, rejectWithValue, getState }) => {
    const { stores } = getState();
    return searchStores(stores.search.searchText)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

const storesSlice = createSlice({
  name: "stores",
  initialState: {
    items: [],
    logItems: [],
    selectedStore: {
      id: 0,
    },
    pagination: paginationState.getInitialState,
    search: searchState.getInitialState,
  },
  reducers: {
    setSelectedStore: (state, actions) => {
      state.selectedStore = actions.payload;
    },
    setItems: (state, actions) => {
      state.items = actions.payload;
    },
    ...paginationState.reducers,
    ...searchState.reducers,
  },
  extraReducers: {
    [getItemsAsync.fulfilled]: (state, actions) => {
      const items = actions.payload;
      state.items = items;
    },
    [searchItemsAsync.fulfilled]: (state, actions) => {
      const items = actions.payload.data;
      state.logItems = items;
    },
  },
});

export const {
  setPageSize,
  setPageNumber,
  setItems,
  setSelectedStore,
  setSearchText,
} = storesSlice.actions;

export const stores = storesSlice.reducer;
