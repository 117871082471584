import React, { useEffect } from "react"
import { getRequestsAsync } from "./store/monitorSlice"
import { requestsItemSelector } from "./store/monitorSelector";
import {shallowEqual, useDispatch, useSelector} from "react-redux"
import { Dialog, ListItem, DialogContent, DialogTitle, DialogActions, Button} from "@material-ui/core";
import { closeDialog } from "../../store/applicationSlice";
import { Table } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import { IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getRequestMonitorItemsAsync, setCurrentViewStatus } from "./store/monitorSlice";
import { cancelScheduleRequest } from "../../services/ScheduleUpdate/scheduleUpdateService";
import { useSnackbar } from "notistack";

const updateStatuses = ['NA','Pending', 'Canceled', 'Complete'];

const RequestListDialog = ({ monitorId }) => {
    const dispatch = useDispatch();
    const requestState = useSelector(requestsItemSelector, shallowEqual);
    const { enqueueSnackbar } = useSnackbar();
    const requests = requestState.map(request => {
        return {
            ...request, scheduledDate: new Date(request.scheduledDate + 'Z').toLocaleString(),
            requestDate: new Date(request.requestDate + 'Z').toLocaleString(),
            updateStatus: updateStatuses[request.updateStatusId]
        }
    })
    useEffect(() => {
        dispatch(getRequestsAsync(monitorId));
    }, [])

    const handleViewRequest = (requestId: number, scheduledDate: string) => {
        dispatch(getRequestMonitorItemsAsync(requestId))
        dispatch(setCurrentViewStatus(`Scheduled:  ${scheduledDate}`))
        dispatch(closeDialog());
    }

    const handleCancelRequest = async (requestId: number) => {
        const result = await cancelScheduleRequest(requestId);
        if (result) {
            enqueueSnackbar("successfully scheduled updates", {
                variant: "success",
            });
        }
        dispatch(closeDialog());
    }

    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return { color: "yellow", backgroundColor: "black" };
            case 2:
                return { color: "red"};
            case 3:
                return { color: "green" };
            default:
                return { color: "black" };
        }
    }

    return (
<>
            <DialogTitle id="alert-dialog-slide-title">{ `Requests`}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Scheduled</TableCell>
                            <TableCell>Requested</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Requested By</TableCell>
                            <TableCell>View</TableCell>
                            <TableCell>Cancel</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {requests.map((request) => {
                            return (
                                <TableRow>
                                    <TableCell>{request.scheduledDate}</TableCell>
                                    <TableCell>{request.requestDate}</TableCell>
                                    <TableCell><span style={getStatusColor(request.updateStatusId)}>{request.updateStatus}</span></TableCell>
                                    <TableCell>{request.requestedBy}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            color="primary"
                                            onClick={() => handleViewRequest(request.id, request.scheduledDate)}
                                        >
                                            <Tooltip title="view update">
                                                <VisibilityIcon />
                                            </Tooltip>
                                        </IconButton>
                                        </TableCell>
                                        <TableCell>
                                        <IconButton
                                            color="secondary"
                                            onClick={() => handleCancelRequest(request.id)}
                                        >
                                            <Tooltip title="cancel request">
                                                <DeleteIcon />
                                            </Tooltip>
                                        </IconButton>

                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                
        </DialogContent>
        <DialogActions>
                <Button onClick={() => {dispatch(closeDialog())}} color="primary">
            Close
          </Button>
        </DialogActions>
      </>

        )
}

export default RequestListDialog;