export class SearchState {
  constructor(name, initialState, extraReducers) {
    this.name = name;
    this.initialState = initialState;
    this.extraReducers = extraReducers;
  }
  getInitialState = {
    searchText: "",
    ...this.initialState,
  };
  reducers = {
    setSearchText: (state, action) => {
      state.search.searchText = action.payload;
    },
  };
  extraReducers = this.extraReducers;
}
