import axios from "axios";
import { apiUrl } from "../../config/appConfig";
import { Role } from "../../models/Role";

const endpoint = `${apiUrl}/api/Roles`;

export const getRoles = async () => {
  return await axios.get(`${endpoint}`);
};

export const getRoleById = async (roleId: string) => {
  return await axios.get(`${endpoint}/${roleId}`);
};

export const saveRole = async (id: number, role: Role) => {
  return await axios.put(`${endpoint}/${id}`, role);
};

export const createRole = async (role: Role) => {
  return await axios.post(`${endpoint}`, role);
};

export const removeRole = async (roleId: number) => {
  return await axios.delete(`${endpoint}/${roleId}/delete`);
};

export const searchRoles = async (searchText: string) => {
  return await axios.get(`${endpoint}/search/${searchText}`);
};
