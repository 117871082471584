import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PaginationState } from "../../../store/pagination/paginationState";
import { searchRoles } from "../../../services/Roles/roleService";

interface ISearchProp {
  searchText: string;
}

const paginationState = new PaginationState(
  "program-management/user/log/pagination",
  {}
);

export const getItemsAsync = createAsyncThunk(
  "monitors/getMonitors",
  async (search: ISearchProp, { rejectWithValue }) => {
    const { searchText } = search;
    return searchRoles(searchText)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    items: [],
    pagination: paginationState.getInitialState,
  },
  reducers: {
    ...paginationState.reducers,
  },
  extraReducers: (builder) => {
    builder.addCase(getItemsAsync.fulfilled, (state, actions) => {
      state.items = actions.payload;
    });
  },
});

export const { setPageSize, setPageNumber } = rolesSlice.actions;

export const roles = rolesSlice.reducer;
