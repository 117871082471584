export class Store {
  public id: number = 0;
  public number: string = "";
  public name: string = "";
  public address: string = "";
  public suite: string = "";
  public city: string = "";
  public state: string = "";
  public zip: string = "";
}
