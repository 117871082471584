import validate from "validate.js";

const userValidator = (menuItem) =>
    validate(menuItem, {
        firstName: {
            presence: { allowEmpty: false, message: "^'First Name' is required" },
        },
        lastName: {
            presence: { allowEmpty: false, message: "^'Last Name' is required" },
        },
        username: {
            presence: { allowEmpty: false, message: "^'User Name' is required" },
        },
    });

export { userValidator };
