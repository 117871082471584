import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const DocumentViewer = () => {
    const handleEditorChange = (content, editor) => {
        console.log('Content was updated:', content);
    }

    return (
        <Editor
            apiKey={
                "mjexfk7mq6lbufs96gtd8pohljr32x5rhs2z94a4bmnkrr66"
            }
                initialValue="<p>Please submit your feedback here...</p>"
                init={{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat | help'
                }}
                onEditorChange={handleEditorChange}
            />
        );
}

export default DocumentViewer;
