import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {PaginationState} from "../../../store/pagination/paginationState";
import {searchUsers} from "../../../services/Users/userService";

interface ISearchProp {
  storeId: number;
  searchText: string;
}

const paginationState = new PaginationState("program-management/user/log/pagination",{});

export const getItemsAsync = createAsyncThunk(
  "users/getUsers",
  async (search: ISearchProp, { dispatch, rejectWithValue }) => {
    const {storeId, searchText} = search;
    return searchUsers(searchText, storeId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        rejectWithValue(error);
      });
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    items: [],
    pagination: paginationState.getInitialState
  },
  reducers: {
    ...paginationState.reducers
  },
  extraReducers: builder => {
    builder.addCase(getItemsAsync.fulfilled, (state, actions) => {
      state.items = actions.payload;
    })
  },
});

export const {
    setPageSize,
    setPageNumber,
} = usersSlice.actions;

export const users = usersSlice.reducer;