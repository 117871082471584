import React, { useContext, createContext, useState, useEffect } from "react";
import * as Cookies from "js-cookie";
import axios from "axios";
import { GlobalUser } from "../models/GlobalUser";
import { Role } from "../models/Role";
import { number } from "prop-types";

export const getCookieExpirationTime = () => {
  // Only store the cookie for 1 hour - at the 55 minute mark an
  // alert will show the user that their session is about to expire
  // and will be given the opportunity to renew their JWT
  return new Date(new Date().getTime() + 60 * 60 * 1000);
};
export const AppContext = createContext<any | null>(null);

interface IAlertParams {
  contentText: string;
  title: string;
  showAlert: boolean;
  // onConfirm: () => any;
  // onDeny: () => any;
}

export const AppContextProvider = (props: any) => {
    const token = Cookies.get("token");
  const [isMobile, setIsMobile] = useState<Boolean>(false);
  const [darkMode, setDarkMode] = useState<Boolean>(false);
  const [authorized, setAuthorized] = useState<boolean>(token || false);
  const [searchText, setSearchText] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [contentText, setContentText] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [globalUser, setGlobalUser] = useState<GlobalUser>(new GlobalUser());
  const [appRoles, setAppRoles] = useState<Array<Role>>([]);
  const [contextPage, setContextPage] = useState<number>(0);
    const [contextRowPerPage, setContextRowPerPage] = useState<number>(5);
    const [isChalkboardUser, setIsChalkboardUser] = useState<boolean>(false);
  const roleHelper = {
    get: () =>{
      return Cookies.get("userRole");
    },
    isAdmin: () =>{
      var role = Cookies.get("userRole");
      return String(role) === "Admin";
    },
    isSuperAdmin: () => {
      var role = Cookies.get("userRole");
      return String(role) === "Super Admin";
    },
  };

  const onConfirm = () => {
    setAuthorized(true);
  };

  const onDeny = () => {
    setAuthorized(false);
  };

  const displayAlert = (alertParams: IAlertParams) => {
    setTitle(alertParams.title);
    setContentText(alertParams.contentText);
    setShowAlert(true);
  };

  const handleResize = () => {
    if (window.innerWidth < 812) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    handleResize();
  }, []);

  const setToken = (token: string) => {
    let d = new Date();
    d.setTime(d.getTime() + 20 * 60 * 1000);
    Cookies.set("token", token);
    setAxiosDefaultAuthorizationHeaderWithJWT(token);
    setSessionTimeoutAlert(d);
  };

  const setAxiosDefaultAuthorizationHeaderWithJWT = (jwt) => {
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
  };

  const checkToken = () => {
    const token = Cookies.get("token");
    if (token) {
      setAxiosDefaultAuthorizationHeaderWithJWT(token);
      setToken(token);
      setAuthorized(true);
    } else {
      logoff();
    }
  };

  const logoff = () => {
    setAuthorized(false);
    Cookies.remove("token");
    setSessionTimeoutAlert(null);
  };
  const renewJWT = async () => {
    return axios.get("/api/Users/RenewJWT");
  };

  const setSessionTimeoutAlert = (currentCookieExpirationTime: any) => {
    let millisecondsUntilAlert = currentCookieExpirationTime - Date.now();
    if (millisecondsUntilAlert > 0) {
      setTimeout(function () {
            Cookies.remove("token");
      }, millisecondsUntilAlert);
    }
  };

  return (
    <AppContext.Provider
      value={{
        isMobile,
        setIsMobile,
        darkMode,
        setDarkMode,
        authorized,
        setAuthorized,
        searchText,
        setSearchText,
        setToken,
        checkToken,
        title,
        contentText,
        onConfirm,
        onDeny,
        showAlert,
        setShowAlert,
        displayAlert,
        logoff,
        globalUser,
        setGlobalUser,
        roleHelper,
        setAppRoles,
        contextPage,
        setContextPage,
        contextRowPerPage,
              setContextRowPerPage, isChalkboardUser, setIsChalkboardUser
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
