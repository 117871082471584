import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import footerStyle from "../../styles/footerStyle";

function Footer({ ...props }) {
  const { classes, fluid, white, center } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
    [classes.center]: center,
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white,
    });

  return (
    <footer className={classes.footer}>
      <div className={container}>
        <p className={classes.center}>
          &copy; {new Date().getFullYear()}{" "}
          <a href="/" className={anchor}>
            {`DMB: build 0.0.0.0`}
          </a>
          {""}
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);
