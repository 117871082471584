import React, { useEffect, useState } from "react";
import { MenuItem, Select, InputLabel } from "@material-ui/core";
import { getMenuItemTypes } from "../../services/MenuItemTypes/menuItemTypeService";
import { MenuItemType } from "../../models/MenuItemType";

const MenuItemTypeSelector = (props: any) => {
  const [menuItemTypes, setMenuItemTypes] = useState<Array<MenuItemType>>([]);
  const [isLoading, setLoading] = useState<Boolean>(false);
  const { name, label, value, onChange, error, helperText } = props;
  useEffect(() => {
    loadMenuItemTypes();
  }, []);

  const loadMenuItemTypes = async () => {
    setLoading(true);
    try {
      const { data: menuItemTypes } = await getMenuItemTypes();
      setMenuItemTypes(menuItemTypes);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select  name={name} value={value} onChange={onChange} fullWidth {...props} variant="outlined">
        {menuItemTypes.map((type) => {
          return <MenuItem value={type.id}>{type.name}</MenuItem>;
        })}
      </Select>
      <div style={{color:"#f44336", fontSize:"0.75rem"}}>{helperText}</div>
    </>
  );
};

export default MenuItemTypeSelector;
