import React, { useState, useEffect } from "react";
import { Provider, connect } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import componentRoutes from "./routes/componentRoutes.jsx";
import { SideBar } from "./components/Sidebar/sideBar.jsx";
import { useHistory } from "react-router";
import "./custom.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Paper, useControlled } from "@material-ui/core";
import packageJson from "../package.json";
import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";
import Login from "./components/Login/LoginPage";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ResetPasswordRequest from "./components/ResetPassword/ResetPasswordRequest";
import { useAppContext } from "./context/AppContext";
import Alert from "./components/Alert/Alert";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Pages from "./components/Pages/Pages";
import Dashboard from "./components/Dashboard/Dashboard";
import GlobalAlert from "./components/GlobalAlert/GlobalAlert";
import { useDispatch } from "react-redux";

const history = createBrowserHistory();

const App = () => {
  return <ClearCacheComponent />;
};

const MainApp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { darkMode, authorized, checkToken, showAlert } = useAppContext();

  const theme = createMuiTheme({
    palette: {
      type: darkMode ? "dark" : "light",
      primary: {
        main: "#744494",
      },
    },
  });

  checkToken();

  return (
    <ThemeProvider theme={theme}>
      <Paper style={{ height: "100vh" }}>
        <Router history={history}>
          <Switch>
            <Route path="/pages" component={Pages} />
            <PrivateRoute path="/" component={Dashboard} />
          </Switch>
                  {showAlert && <Alert />}
                  <GlobalAlert />
        </Router>
      </Paper>
    </ThemeProvider>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

const mapStateToProps = (state: { settings: { darkmode: boolean } }) => ({
  settings: state.settings.darkmode,
});

export default App;
