import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
    dialogContentSelector,
    showDialogSelector,
} from "../../store/applicationSelector";
import { shallowEqual, useSelector } from "react-redux";

const GlobalAlert = () => {
    const showDialog = useSelector(showDialogSelector, shallowEqual);
    const dialogContent = useSelector(dialogContentSelector, shallowEqual);
    const { children, fullWidth, maxWidth, width } = dialogContent;
    return (
        <div>
            <Dialog
                open={showDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={fullWidth}
                maxWidth={maxWidth}
            // width={width}
            // maxWidth= "md"
            >
                {children}

            </Dialog>
        </div>
    );
};

export default GlobalAlert;
