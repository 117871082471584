import {MonitorItem} from "./MonitorItem";

export class MenuItem{
    public id: number=0;
    public name: string = '';
    public description: string ='';
    public price: number=0.00;
    public calories: string='';
    public extra: string='';
    public menuItemTypeId: number=0;
    public monitorItemId: number=0;
    public monitorItems: Array<MonitorItem> = [];
}