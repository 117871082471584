/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Group } from '../../models/Group';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: 1000,
            '& > * + *': {
                marginTop: theme.spacing(3),
            },
        },
    }),
);

interface AutoCompleteMultiProps {
    data : Group[];
    labelOption: string;
}

export default function AutoCompleteMulti(props: AutoCompleteMultiProps) {
    const classes = useStyles();
    const { data, labelOption } = props;



    const handleChange = (event: any) => {
        let eventHandled = event;

    }
    const handleClose = (event: any) => {
        let eventHandled = event;
    }

    return (
        <div className={classes.root}>
            <Autocomplete
                multiple
                id="tags-standard"
                options={data}
                getOptionLabel={(option) => option[labelOption]}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={labelOption}
                        placeholder="Recipients"
                    />
                )}
                onChange={handleChange}
                onClose={handleClose}
            />
        </div>
    );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
