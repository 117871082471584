import { createSlice } from "@reduxjs/toolkit";

const applicationSlice = createSlice({
    name: "application",
    initialState: {
        showDialog: false,
        dialogContent: {},
    },
    reducers: {

        openDialog: (state, action) => {
            state.showDialog = true;
            state.dialogContent = action.payload;
        },
        closeDialog: (state) => {
            state.showDialog = false;
        },

    },
});

export const { openDialog, closeDialog} = applicationSlice.actions;

export const application = applicationSlice.reducer;